import React, { useState, useEffect } from 'react';
import API from 'services/APIService';
import {
  Form,
  Input,
  Spin,
  Button,
  message,
  Divider
} from "antd";
import UploadFile from '../../../../components/UploadFile';
import BadgeCondition from './BadgeCondition';

const FormItem = Form.Item;
const { TextArea } = Input;

const formItemLayout = {
    labelCol: {
        lg: { span: 6 },
    },
    wrapperCol: {
        lg: { span: 18 },
    },
};

const BadgeForm = (props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (props.match.params.id !== 'new') {
            API.badge_read(props.match.params.id)
                .then((response) => {
                    const data = response.data;
                    setFieldsValue({
                        icon: data.icon,
                        name: data.name,
                        description: data.description,
                        conditions: data.conditions.map(condition => ({
                            action: condition.action,
                            condition: condition.condition
                        }))
                    })
                })
                .catch((error) => {
                    if (error.response.data) { message.error(error.response.data.messages[0]); }
                    else { message.error(error.message); }
                    setLoading(false);
                });
        }
    }, [props.match.params.id]);

    const _handleSubmit = () => {
        setLoading(true);
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                if (props.match.params.id === 'new') {
                    API.badge_create(values)
                        .then(() => {
                            message.success('Badge Created', 1);
                            setLoading(false);
                            props.history.push('/badges');
                        })
                        .catch((error) => {
                            if (error.response.data) { message.error(error.response.data.messages[0]); }
                            else { message.error(error.message); }
                            setLoading(false);
                        });
                } else {
                    API.badge_update(props.match.params.id, values)
                        .then(() => {
                            message.success('Badge Updated', 1);
                            setLoading(false);
                            props.history.push('/badges');
                        })
                        .catch((error) => {
                            if (error.response.data) { message.error(error.response.data.messages[0]); }
                            else { message.error(error.message); }
                            setLoading(false);
                        });
                }
            } else {
                message.error('Submit Error!', 4);
                setLoading(false);
            }
        });
    }

    return (
        <div>
            <h1>{ props.match.params.id === 'new' ? 'Create Badge' : 'Edit Badge' }</h1>
            <div>
                <Form layout='vertical'>
                    <FormItem 
                        {...formItemLayout} 
                        label="Icon" 
                    >
                        {
                            getFieldDecorator(
                                'icon', 
                                { 
                                    rules: [{ required: true, message: 'Please input!', }],
                                }
                            )(
                                <UploadFile />
                            )
                        }
                    </FormItem>
                    <FormItem 
                        {...formItemLayout} 
                        label="Name" 
                    >
                        {
                            getFieldDecorator(
                                'name', 
                                { rules: [{ required: true, message: 'Please input!', }] }
                            )(
                                <Input />
                            )
                        }
                    </FormItem>
                    <FormItem 
                        {...formItemLayout} 
                        label="Description" 
                    >
                        {
                            getFieldDecorator(
                                'description', 
                                { rules: [{ required: true, message: 'Please input!', }] }
                            )(
                                <TextArea rows={5} />
                            )
                        }
                    </FormItem>
                    <Divider dashed />
                    <h3>Conditions</h3>
                        <FormItem 
                            {...formItemLayout} 
                            label="Conditions" 
                        >
                            {
                                getFieldDecorator(
                                    'conditions', 
                                    { rules: [{ required: true, message: 'Please input!', }] }
                                )(
                                    <BadgeCondition />
                                )
                            }
                        </FormItem>
                    <Divider dashed />
                    <FormItem style={{ textAlign: 'center' }}>
                        <Button 
                            loading={loading} 
                            type="primary" 
                            onClick={_handleSubmit}
                            style={{ width: '40%' }}
                        >
                            Submit
                        </Button>
                    </FormItem>
                </Form>
            </div>
            {
                loading && (
                    <div 
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Spin />
                    </div>
                )
            }
        </div>
    );
}

const Badge = Form.create()(BadgeForm);
export default Badge;
