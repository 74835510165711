import React, { Component } from 'react';

import AuthService from 'services/AuthService';
import API from 'services/APIService';
import { message } from 'antd';
import { isArray } from 'lodash';
import { Spin, Radio } from 'antd';

import { Form, Input, Button } from 'antd';
import moment from 'moment';

const FormItem = Form.Item;

var DEFAULT_STATE = {
    submit_loading: false,
    item_loading: false,
    item: {},
    title: 'New Admin User',
    form_sizes: [],
    form_images: []
}

const formItemLayout = {
    labelCol: {
        lg: { span: 6 },
    },
    wrapperCol: {
        lg: { span: 18 },
    },
};

class Wrapper extends Component {
    constructor(props) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    componentDidMount() {
        console.log("componentDidMount");
        this.setState(DEFAULT_STATE);
        if (this.props.match.params.id === 'me') {
            this._loadData(AuthService.get().id);
        }
        else if (this.props.match.params.id !== 'new') {
            this._loadData(this.props.match.params.id);
        }
        else {
            this.setState(DEFAULT_STATE);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            console.log("componentDidUpdate");
            if (this.props.match.params.id === 'me') {
                this._loadData(AuthService.get().id);
            }
            else if (this.props.match.params.id !== 'new') {
                this._loadData(this.props.match.params.id);
            }
            else {
                this.setState(DEFAULT_STATE);
            }
        }
    }

    _loadData = (id) => {
        this.setState({ item_loading: true });
        this.setState({ title: 'View User' });
        API.user_read(id)
            .then((response) => {
                var data = response.data;
                this.setState({
                    item_loading: false,
                    item: data,
                    form_images: [data.image],
                });
            })
            .catch((error) => {
                if (error.response.data) { message.error(error.response.data.messages[0]); }
                else { message.error(error.message); }
                this.setState({ item_loading: false });
                console.log(error);
            })
    }

    _handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ submit_loading: true });
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                var payload = values;
                // create custom payload
                if (this.props.match.params.id === 'new') {
                    API.user_create_admin(payload)
                        .then((response) => {
                            message.success('User Created', 1);
                            this.setState({ submit_loading: false })
                            this.props.history.push('/users');
                            window.location.reload();
                        })
                        .catch((error) => {
                            if (error.response.data) { message.error(error.response.data.messages[0]); }
                            else { message.error(error.message); }
                            this.setState({ submit_loading: false });
                            console.log(error);
                        });
                }
                else {
                    delete (payload.email);
                    API.user_update(this.props.match.params.id === 'me' ? AuthService.get().id: this.props.match.params.id, payload)
                        .then((response) => {
                            message.success('User Updated', 1);
                            this.setState({ submit_loading: false });
                            this.props.history.push('/users');
                        })
                        .catch((error) => {
                            if (error.response.data) { message.error(error.response.data.messages[0]); }
                            else { message.error(error.message); }
                            this.setState({ submit_loading: false });
                            console.log(error);
                        });
                }
            }
            else {
                message.error('Submit Error!', 4);
                this.setState({ submit_loading: false });
                console.log(err);
            }
        });

    }

    postUserUpdate = (form) => {

        form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    const newData = {
                        ...this.state.item,
                        ...values,
                        membersId: String(values.membersId)
                    }
                    delete newData.email
                    API.user_update(this.state.item.id, newData).then(
                        response => {
                            message.success('User Updated');
                        }
                    ).catch(error => 
                       message.error(error.message) 
                    )
                } 
            })

    }

    render() {
        if (this.state.item_loading) {
            return <div style={{ textAlign: 'center' }}><Spin size='large' style={{ margin: '150px' }} /></div>
        }
        if (this.state.item.type !== "admin" && (this.props.match.params.id !== 'new')) {
            return this.renderAppUserView();
        } else {
            return this.renderAdminView();
        }
    }

    renderAdminView = () => {
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <h1>{this.state.title}</h1>
                <Form onSubmit={this._handleSubmit} layout='vertical'>
                    <FormItem {...formItemLayout} label="Email" >
                        {getFieldDecorator('email', {
                            rules: [{
                                required: true, message: 'Please input!',
                            }],
                            initialValue: this.state.item.email
                        })(
                            <Input autoComplete="new-email" disabled={this.props.match.params.id !== 'new'}/>
                        )}
                    </FormItem>
                    <FormItem {...formItemLayout} label="Name" >
                        {getFieldDecorator('name', {
                            rules: [{
                                required: true, message: 'Please input!',
                            }],
                            initialValue: this.state.item.name
                        })(
                            <Input />
                        )}
                    </FormItem>
                    <FormItem {...formItemLayout} label="Password" >
                        {getFieldDecorator('password', {
                            rules: [{
                                required: false, message: 'Please input!',
                            }],
                            initialValue: this.state.item.password
                        })(
                            <Input type="password" autoComplete="new-password"/>
                        )}
                    </FormItem>
                    <FormItem style={{ textAlign: 'center' }}>
                        <Button loading={this.state.loading} type="primary" htmlType="submit" className="login-form-button" style={{ width: '40%' }}>
                            Submit
                        </Button>
                    </FormItem>
                </Form>
            </div>
        );
    }

    renderAppUserView = () => {
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <h1>{this.state.title}</h1>
                <Form>
                    {
                        !!this.state.item.profile_photo &&
                        <Form.Item
                            labelCol={{ span: 0 }}
                            wrapperCol={{ span: 12 }}
                            style={{ margin: '0px', padding: '0px' }}
                        >
                            <img 
                                src={API.get_base() + '/' + this.state.item.profile_photo}
                                style={{
                                    minHeight: "20vh",
                                    maxHeight: "20vh",
                                    borderRadius: "4px"
                                }}
                                alt='user'
                            />
                        </Form.Item>
                    }
                    <Form.Item
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 12 }}
                        style={{ margin: '0px', padding: '0px' }}
                        label={"Name"}
                    >
                        <span style={{ fontWeight: "bold" }}>{this.state.item.name} { this.renderMembershipStatus() }</span>
                    </Form.Item>
                    <Form.Item
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 12 }}
                        style={{ margin: '0px', padding: '0px' }}
                        label={"Email"}
                    >
                        <span style={{ fontWeight: "bold" }}>{this.state.item.email}</span>
                    </Form.Item>
                    <Form.Item
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 12 }}
                        style={{ margin: '0px', padding: '0px' }}
                        label={"Address"}
                    >
                        <span style={{ fontWeight: "bold" }}>{this.state.item.address || 'N/A'}</span>
                    </Form.Item>
                    <Form.Item
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 12 }}
                        style={{ margin: '0px', padding: '0px' }}
                        label={"Contact Number"}
                    >
                        <span style={{ fontWeight: "bold" }}>{this.state.item.contact_number || 'N/A'}</span>
                    </Form.Item>
                    <Form.Item
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 12 }}
                        style={{ margin: '0px', padding: '0px' }}
                        label={"Affiliation"}
                    >
                        <span style={{ fontWeight: "bold" }}>{this.state.item.affiliation || 'N/A'}</span>
                    </Form.Item>
                    <Form.Item
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 12 }}
                        style={{ margin: '0px', padding: '0px' }}
                        label={"Organization"}
                    >
                        <span style={{ fontWeight: "bold" }}>{this.state.item.organization || 'N/A'}</span>
                    </Form.Item>
                    { this.state.item.is_applying && this.renderUpgradeInfo() }
                    <Form.Item
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 12 }}
                        style={{ margin: '0px', padding: '0px' }}
                        label={"Type"}
                    >
                        {getFieldDecorator('has_passport', {
                            initialValue: this.state.item.has_passport ? 
                                this.state.item.has_passport :
                                0,
                            rules: [
                                {
                                    required: true,
                                    message: "Please choose a Passport type"
                                }
                            ]
                        }
                        )(
                            <Radio.Group>
                                <Radio.Button value={1}>With Passport</Radio.Button>
                                <Radio.Button value={0}>Without Passport</Radio.Button>
                            </Radio.Group>
                        )}
                    </Form.Item>
                    <Form.Item
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 12 }}
                        style={{ margin: '0px', padding: '0px' }}
                        label={"Member's ID"}
                        colon
                    >
                        {getFieldDecorator('members_id', {
                            initialValue: this.state.item.members_id ? 
                                this.state.item.members_id :
                                "",
                            rules: [
                                {
                                    required: true,
                                    message: "Please input the member's ID"
                                }
                            ]
                        }
                        )(
                            <Input />
                       )}
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 12, offset: 2}}>
                        <Button 
                            onClick={() => this.postUserUpdate(this.props.form)}
                            type="primary"
                        >
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }

    renderMembershipStatus = () => { 
        let status;
        if (this.state.item.is_disabled === true) {
            status = <span>Disabled</span>
        }
        else if (this.state.item.type === 'admin') {
            status = <span>Active admin</span>
        }
        else if (this.state.item.is_verified === false) {
            status = <span>Pending verification</span>
        }
        else if (this.state.item.is_approved === false && this.state.item.is_applying === true) {
            status = <span>Pending approval</span>
        }
        else if (this.state.item.is_applying === true) {
            status = <span>Membership approved</span>
        }
        else {
            status = <span>Volunteer</span>
        }
        return status;
    }

    renderUpgradeInfo = () => {
        return (
            <React.Fragment>
                <Form.Item
                    labelCol={{ span: 3 }}
                    wrapperCol={{ span: 12 }}
                    style={{ margin: '0px', padding: '0px' }}
                    label={"Is Diver?"}
                >
                    <span style={{ fontWeight: "bold" }}>{this.state.item.is_diver ? 'Yes' : 'No'}</span>
                </Form.Item>
                {
                    this.state.item.is_diver &&
                    <React.Fragment>
                        <Form.Item
                            labelCol={{ span: 3 }}
                            wrapperCol={{ span: 12 }}
                            style={{ margin: '0px', padding: '0px' }}
                            label={"Diver Certification"}
                        >
                            <span style={{ fontWeight: "bold" }}>{this.state.item.diver_certification || 'N/A'}</span>
                        </Form.Item>
                        <Form.Item
                            labelCol={{ span: 3 }}
                            wrapperCol={{ span: 12 }}
                            style={{ margin: '0px', padding: '0px' }}
                            label={"Agency"}
                        >
                            <span style={{ fontWeight: "bold" }}>{this.state.item.agency || 'N/A'}</span>
                        </Form.Item>
                        <Form.Item
                            labelCol={{ span: 3 }}
                            wrapperCol={{ span: 12 }}
                            style={{ margin: '0px', padding: '0px' }}
                            label={"Number of Dives"}
                        >
                            <span style={{ fontWeight: "bold" }}>{this.state.item.number_of_dives || 'N/A'}</span>
                        </Form.Item>
                        <Form.Item
                            labelCol={{ span: 3 }}
                            wrapperCol={{ span: 12 }}
                            style={{ margin: '0px', padding: '0px' }}
                            label={"Date of Last Dive"}
                        >
                            <span style={{ fontWeight: "bold" }}>{!this.state.item.date_of_last_dive ? 'N/A' : moment(this.state.item.date_of_last_dive).format('MMM-DD-YYYY')}</span>
                        </Form.Item>
                        <Form.Item
                            labelCol={{ span: 3 }}
                            wrapperCol={{ span: 12 }}
                            style={{ margin: '0px', padding: '0px' }}
                            label={"Has Equipment?"}
                        >
                            <span style={{ fontWeight: "bold" }}>{this.state.item.has_equipment ? 'Yes' : 'No'}</span>
                        </Form.Item>
                    </React.Fragment>
                }
                <Form.Item
                    labelCol={{ span: 3 }}
                    wrapperCol={{ span: 12 }}
                    style={{ margin: '0px', padding: '0px' }}
                    label={"Rashguard Size"}
                >
                    <span style={{ fontWeight: "bold" }}>{this.state.item.rashguard_size || 'N/A'}</span>
                </Form.Item>
                <Form.Item
                    labelCol={{ span: 3 }}
                    wrapperCol={{ span: 12 }}
                    style={{ margin: '0px', padding: '0px' }}
                    label={"Field of Expertise"}
                >
                    <span style={{ fontWeight: "bold" }}>{this.state.item.field_of_expertise || 'N/A'}</span>
                </Form.Item>
                <Form.Item
                    labelCol={{ span: 3 }}
                    wrapperCol={{ span: 12 }}
                    style={{ margin: '0px', padding: '0px' }}
                    label={"Past Projects"}
                >
                    <span style={{ fontWeight: "bold" }}>{this.state.item.past_projects || 'N/A'}</span>
                </Form.Item>
                <Form.Item
                    labelCol={{ span: 3 }}
                    wrapperCol={{ span: 12 }}
                    style={{ margin: '0px', padding: '0px' }}
                    label={"Purpose"}
                >
                    {
                        isArray(this.state.item.arr_purposes) &&
                        this.state.item.arr_purposes.map(function(item) {
                            return <div style={{ fontWeight: "bold" }}>{item}</div>
                        })
                    }    
                </Form.Item>
                <Form.Item
                    labelCol={{ span: 3 }}
                    wrapperCol={{ span: 12 }}
                    style={{ margin: '0px', padding: '0px' }}
                    label={"Expectations"}
                >
                    {
                        isArray(this.state.item.arr_expectations) &&
                        this.state.item.arr_expectations.map(function(item) {
                            return <div style={{ fontWeight: "bold" }}>{item}</div>
                        })
                    }    
                </Form.Item>
                <Form.Item
                    labelCol={{ span: 3 }}
                    wrapperCol={{ span: 12 }}
                    style={{ margin: '0px', padding: '0px' }}
                    label={"Activities"}
                >
                    {
                        isArray(this.state.item.arr_activities) &&
                        this.state.item.arr_activities.map(function(item) {
                            return <div style={{ fontWeight: "bold" }}>{item}</div>
                        })
                    }    
                </Form.Item>
            </React.Fragment>
        );
    }
}

const User = Form.create()(Wrapper);
export default User;
