import React from 'react';
import API from 'services/APIService';
import { Card, message, Upload, Icon, Row, Col, Button } from 'antd';
import { getImageUrl } from '../utils/image';

const MultipleImageUpload = React.forwardRef(({
    value = [],
    onChange,
    limit = 0,
    span
}, _) => {
    const _deleteImage = (index) => {
        const newImages = value;
        newImages.splice(index, 1);
        onChange(newImages);
    }
    const _onUploadSuccess = (file_loc) => {
        const newImages = value;
        newImages.push('/image?file_loc=' + file_loc);
        onChange(newImages);
    }

    const columnSpan = !!span ? span : limit === 1 ? 24 : 8;
    return (
        <div>
            <Row gutter={16} type={"flex"}>
                {
                    value.map((item, index) => {
                        return (
                            <Col key={index} span={columnSpan} style={{marginTop: '10px'}}>
                                <Card title={''} bordered={true} extra={<a onClick={() => _deleteImage(index)}>Delete</a>}>
                                    <img width="100%" src={getImageUrl(item)} alt='random' />
                                </Card>
                            </Col>
                        );
                    })
                }
                {
                    (limit === 0 || value.length) < limit && (
                        <Col span={8} style={{ marginTop: '10px' }}>
                            <Uploader onSuccess={_onUploadSuccess} />
                        </Col>
                    )
                }
            </Row>
        </div>
    );
})

export default MultipleImageUpload;

const Uploader = ({
    onSuccess
}) => {
    const _onChange = (info) => {
        let fileList = info.fileList;
        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        fileList = fileList.slice(-1);
        // 2. read from response and show file link
        fileList = fileList.map((file) => {
            if (file.response) {
                if (file.response.error) {
                    message.error(file.response.messages[0]);
                } else {
                    file.file_loc = file.response.file_loc;
                }
            }
            return file;
        });
        if (fileList[0].file_loc) {
            onSuccess(fileList[0].file_loc);
        }
    }

    return (
        <div>
            <Upload
                accept='image/*'
                action={API.image_upload_endpoint()}
                multiple={false}
                headers={API.token_header()}
                data={{}}
                listType='text'
                onChange={_onChange}
            >
                <Button>
                    <Icon type="upload" /> Click to upload
                </Button>
            </Upload>
        </div>
    );
}
