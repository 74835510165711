import React from 'react';
import './App.css';
import 'react-quill/dist/quill.snow.css'; // ES6

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Landing from 'containers/Landing/Landing.js';
import Dashboard from 'containers/Dashboard/Dashboard.js';

import { message } from 'antd';

const App = () => {
  return (
    <div className="App" style={{ height: '100%', background: "#e8f4fe" }}>
      <BrowserRouter>
        <Switch>
          <Route path={["/login", "/logout"]} component={Landing} />
          <Route path="/" component={Dashboard} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;

message.config({
  top: 65,
  duration: 3,
});
