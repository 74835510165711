import React, {Component} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import { Layout, Menu } from 'antd';
import { NavLink } from 'react-router-dom';

import AuthService from 'services/AuthService';
import ViewUserList from './pages/Users/UserList';
import User from './pages/Users/User';
import MessageList from './pages/Messages/MessageList';
import SiteList from './pages/Sites/SiteList';
import ProjectList from './pages/Projects/ProjectList';
import EventList from './pages/Events/EventList';
import PartnerList from './pages/Partners/PartnerList';
import ProductList from './pages/Products/ProductList';
import TypeList from './pages/Types/TypeList';
import BadgeList from './pages/Badges/BadgeList';

import Site from './pages/Sites/Site';
import Project from './pages/Projects/Project';
import Event from './pages/Events/Event';
import Product from './pages/Products/Product';
import Partner from './pages/Partners/Partner';
import Type from './pages/Types/Type';
import Badge from './pages/Badges/Badge';
import TrashCollections from './pages/TrashCollections/TrashCollections';

import AddPerksForm from './pages/Partners/AddPerks/AddPerksForm/AddPerksForm';

const { Header, Content, Footer } = Layout;
const { SubMenu } = Menu;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      group_options: []
    }
  }

  componentDidMount() {
    
  }

  render() {
    if(!AuthService.isLoggedIn()) {
      this.props.history.push('/login');
      return null;
    }
    var menu;
    menu = <Menu theme="light" mode="horizontal" style={{ lineHeight: '62px', float: 'right', border: '0px'}}>
      <Menu.Item key="1"><NavLink to='/users'>Users</NavLink></Menu.Item>
      <Menu.Item key="2"><NavLink to='/messages'>Messages</NavLink></Menu.Item>
      <Menu.Item key="3"><NavLink to='/sites'>Sites</NavLink></Menu.Item>
      <Menu.Item key="4"><NavLink to='/projects'>Projects</NavLink></Menu.Item>
      <Menu.Item key="5"><NavLink to='/events'>Events</NavLink></Menu.Item>
      <Menu.Item key="6"><NavLink to='/partners'>Partners</NavLink></Menu.Item>
      <Menu.Item key="7"><NavLink to='/products'>Products</NavLink></Menu.Item>
      <Menu.Item key="8"><NavLink to='/types'>Types</NavLink></Menu.Item>
      <Menu.Item key="9"><NavLink to='/badges'>Badges</NavLink></Menu.Item>
      <SubMenu title="My Account">
        <Menu.Item><NavLink to='/user/me' >Edit Profile</NavLink></Menu.Item>
        <Menu.Item><NavLink to='/logout'>Logout</NavLink></Menu.Item>
      </SubMenu>
    </Menu>

    var formDiv;
    return (
      <Layout className="layout" style={{ background: '#e8f4fe'}}> 
        <Header style={{ position: 'fixed', width: '100%', backgroundColor: "#fff" , zIndex: 10000, borderBottom: '1px solid #ccc'}}>
          <div className="logo" />
          {menu}
        </Header>
        <Content style={{marginTop: '65px', padding: '0px 50px 0px 50px'}}>
        <p style={{float: 'right', padding: '10px', margin: '0px'}}>Logged in as <b>{AuthService.get().email}</b></p>
        </Content>
        <Content style={{ padding: '0px 50px 0px 50px', marginTop: '0px' }}>
          <div style={{ background: '#fff', padding: 24, minHeight: 500 }}>
          <Switch>
            <Route path="/users" name="User Management" render={() => <ViewUserList ref={(instance) => { this.userlist = instance; }} /> } />
            <Route path="/user/:id" name="User Details" component={User} />
            <Route path="/messages" name="Message Management" component={MessageList} />
            <Route path="/sites" name="Sites Management" component={SiteList} />
            <Route path="/site/:id" name="Site" component={Site} />
            <Route path="/projects" name="Projects Management" component={ProjectList} />
            <Route path="/project/:id" name="Project" component={Project} />
            <Route path="/events" name="Events Management" component={EventList} />
            <Route path="/event/trash/:id" name="Trash Collection" component={TrashCollections} />
            <Route path="/event/:id" name="Event" component={Event} />
            <Route exact path="/partners" name="Partners Management" component={PartnerList} />
            <Route exact path="/partner/:id" name="Partner" component={Partner} />
            {
              /**
               * promoId - an instance of an existing promo
               * promoId is set to "new" when creating a new instance promo
               */
              <Route exact path="/partner/:id/promo/:promoId" name="Add Partner Promo" component={AddPerksForm} />
            }
            <Route path="/products" name="Products Management" component={ProductList} />
            <Route path="/product/:id" name="Product" component={Product} />
            <Route path="/types" name="Types Management" component={TypeList} />
            <Route path="/type/:id" name="Product" component={Type} />
            <Route path="/badges" name="Badges Management" component={BadgeList} />
            <Route path="/badge/:id" name="Badge" component={Badge} />

            <Route path="/" render={() => <Redirect to="/users" />}/>
          </Switch>
          {formDiv}

          </div>
        </Content>
        <Footer style={{ textAlign: 'center',background: '#e8f4fe'  }}>
          Shore It Up! © 2018
        </Footer>
      </Layout>
    )
  }
}

export default Dashboard;
