import React, { useState, useEffect } from 'react';
import moment from 'moment';
import API from 'services/APIService';
import { 
    message, 
    List, 
    Avatar, 
    Tag, 
    Switch as AntDSwitch, 
    Spin, 
    Form, 
    Input, 
    Button, 
    DatePicker,
    Drawer
} from 'antd';
import get from 'lodash/get';

import RegistrationsList from './RegistrationsList';
import SiteSelect from './components/SiteSelect';
import PartnerSelect from './components/PartnerSelect';
import MultipleImageUpload from '../../../../components/MultipleImageUpload';
import GoogleMapPlaces from './components/GoogleMapPlaces';

const FormItem = Form.Item;
const { TextArea } = Input;

const formItemLayout = {
    labelCol: {
        lg: { span: 6 },
    },
    wrapperCol: {
        lg: { span: 18 },
    },
};

const Event = React.forwardRef(({
    match,
    form,
    history
}, _) => {
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [event, setEvent] = useState();
    const [partners, setPartners] = useState([]);
    const [partnersLoading, setPartnersLoading] = useState([]);
    const [registrations, setRegistrations] = useState([]);
    const [title, setTitle] = useState('New Event');
    const [position, setPosition] = useState();
    const [showTrashCollection, setShowTrashCollection] = useState(false);

    useEffect(() => {
        _loadPartners();
        if (!match.params.id || match.params.id === 'new') {
            setShowTrashCollection(false);
            return;
        }
        setTitle('Edit Event');
        setShowTrashCollection(true);
        _loadData(match.params.id);
    }, [match.params.id]);

    const _loadData = (id) => {
        setLoading(true);
        API.event_read(id)
        .then((response) => {
            setEvent(response.data);
            setPosition({
                lat: response.data.latitude,
                lng: response.data.longitude
            });
            return API.event_registrations(id);
        })
        .then((response) => {
            setRegistrations(response.data);
        })
        .catch((error) => {
            if (get(error, 'response.data')) { message.error(get(error, 'response.data.messages.0')); }
            else { message.error(error.message); }
        })
        .finally(() => setLoading(false));
    }

    const _loadPartners = () => {
        setPartnersLoading(true);
        API.partner_list()
        .then((response) => {
            setPartners(response.data.data);
        })
        .catch((error) => {
            if (get(error, 'response.data')) { message.error(get(error, 'response.data.messages.0')); }
            else { message.error(error.message); }
        })
        .finally(() => setPartnersLoading(false));
    }

    const _handleSubmit = () => {
        setSubmitting(true);
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const payload = values;
                payload.latitude = position.lat;
                payload.longitude = position.lng;
                if (match.params.id === 'new') {
                    API.event_create(payload)
                    .then(() => {
                        message.success('Event Created', 1);
                        setSubmitting(false);
                        history.push('/events');
                    })
                    .catch((error) => {
                        if (error.response.data) { message.error(error.response.data.messages[0]); }
                        else { message.error(error.message); }
                        setSubmitting(false);
                    });
                } else {
                    API.event_update(match.params.id, payload)
                    .then(() => {
                        message.success('Event Updated', 1);
                        setSubmitting(false);
                        history.push('/events');
                    })
                    .catch((error) => {
                        if (error.response.data) { message.error(error.response.data.messages[0]); }
                        else { message.error(error.message); }
                        setSubmitting(false);
                    });
                }
            } else {
                message.error('Submit Error!', 4);
                setSubmitting(false);
            }
        });
    }

    const _setSelectedPosition = (position) => {
        setPosition(position);
    }

    if (loading) {
        return (
            <div style={{ textAlign: 'center' }}>
                <Spin size='large' style={{ margin: '150px' }} />
            </div>
        );
    }
    const { getFieldDecorator, getFieldValue, setFieldsValue } = form;

    const _removePartner = (partnerId) => {
        const partnersInput = getFieldValue('partners');
        setFieldsValue({ partners: partnersInput.filter(id => id !== partnerId) });
    }

    const partnersInput = getFieldValue('partners');
    return (
        <div>
            <h1>{title}</h1>
            <div>
                <Form layout='vertical'>
                    <FormItem {...formItemLayout} label="Pre-Event" >
                        {getFieldDecorator('pre_event', {
                            rules: [{
                                required: true, message: 'Please input!',
                            }],
                            valuePropName: "checked",
                            initialValue: get(event, 'pre_event', true)
                        })(
                            <AntDSwitch
                                checkedChildren="Pre-Event"
                                unCheckedChildren="Post-Event"
                            />
                        )}
                    </FormItem>
                    <FormItem {...formItemLayout} label="Name" >
                        {getFieldDecorator('name', {
                            rules: [{
                                required: true, message: 'Please input!',
                            }],
                            initialValue: get(event, 'name')
                        })(
                            <Input />
                        )}
                    </FormItem>
                    <FormItem {...formItemLayout} label="Clean Up Event" >
                        {getFieldDecorator('is_clean_up', {
                            rules: [{
                                required: true, message: 'Please input!',
                            }],
                            valuePropName: "checked",
                            initialValue: get(event, 'is_clean_up', true)
                        })(
                            <AntDSwitch />
                        )}
                    </FormItem>
                    <FormItem {...formItemLayout} label="Site" >
                        {getFieldDecorator('site_id', {
                            rules: [{
                                required: true, message: 'Please input!',
                            }],
                            initialValue: get(event, 'site_id')
                        })(
                            <SiteSelect />
                        )}
                    </FormItem>
                    <FormItem {...formItemLayout} label="Event Images" >
                        {getFieldDecorator('images', {
                            rules: [{
                                required: true, message: 'Please input!',
                            }],
                            initialValue: get(event, 'images')
                        })(
                            <MultipleImageUpload limit={10} span={8} />
                        )}
                    </FormItem>
                    <GoogleMapPlaces
                        form={form}
                        formItemLayout={formItemLayout}
                        setSelected={_setSelectedPosition}
                        selected={position}
                        address={get(event, 'address')}
                    />
                    <FormItem {...formItemLayout} label="Start Date" >
                        {getFieldDecorator('start_date', {
                            rules: [{
                                required: true, message: 'Please input!',
                            }],
                            initialValue: get(event, 'start_date'),
                            normalize: (value) => {
                                if (moment.isMoment(value)) {
                                    return value.format('YYYY-MM-DD');
                                }
                                return value;
                            },
                            getValueProps: (value) => {
                                if (value) {
                                    return { 
                                        value: moment(value)
                                    }
                                }
                                return { value };
                            }
                        })(
                            <DatePicker />
                        )}
                    </FormItem>
                    <FormItem {...formItemLayout} label="End Date" >
                        {getFieldDecorator('end_date', {
                            rules: [{
                                required: true, message: 'Please input!',
                            }],
                            initialValue: get(event, 'end_date'),
                            normalize: (value) => {
                                if (moment.isMoment(value)) {
                                    return value.format('YYYY-MM-DD');
                                }
                                return value;
                            },
                            getValueProps: (value) => {
                                if (value) {
                                    return { 
                                        value: moment(value)
                                    }
                                }
                                return { value };
                            }
                        })(
                            <DatePicker />
                        )}
                    </FormItem>
                    <FormItem 
                        {...formItemLayout} 
                        label="Pre-Event Reminders" 
                    >
                        {getFieldDecorator('full_description', {
                            rules: [{
                                required: true, message: 'Please input!',
                            }],
                            initialValue: get(event, 'full_description')
                        })(
                            <TextArea rows={5} />

                        )}
                    </FormItem>
                    <FormItem 
                        {...formItemLayout} 
                        label="Post-Event Details" 
                    >
                        {getFieldDecorator("post_event_details" , {
                            rules: [{
                                required: true, message: 'Please input!',
                            }],
                            initialValue: get(event, 'post_event_details')
                        })(
                            <TextArea rows={5} />
                        )}
                    </FormItem>
                    <FormItem 
                        {...formItemLayout} 
                        label="Date to Publish" 
                        style={{ display: getFieldValue("pre_event") === false ? "none" : "" }}
                    >
                        {getFieldDecorator('date_to_publish', {
                            rules: [{
                                required: getFieldValue("pre_event"), message: 'Please input!',
                            }],
                            initialValue: get(event, 'date_to_publish'),
                            normalize: (value) => {
                                if (moment.isMoment(value)) {
                                    return value.format('YYYY-MM-DD');
                                }
                                return value;
                            },
                            getValueProps: (value) => {
                                if (value) {
                                    return { 
                                        value: moment(value)
                                    }
                                }
                                return { value };
                            }
                        })(
                            <DatePicker />
                        )}
                    </FormItem>
                    <Form.Item 
                        {...formItemLayout}
                        label="LGU Partners"
                    >
                        {getFieldDecorator('lgu_partners', {
                            initialValue: get(event, 'lgu_partners', []).map(partner => partner.id)
                        })(
                            <PartnerSelect 
                                options={partners.filter(partner => partner.is_lgu)} 
                                placeholder='Please select LGU partner/s' 
                                loading={partnersLoading}
                            />
                        )}
                    </Form.Item>
                    <Form.Item 
                        {...formItemLayout}
                        label="Partners"
                    >
                        {getFieldDecorator('partners', {
                            initialValue: get(event, 'partners', []).map(partner => partner.id)
                        })(
                            <PartnerSelect 
                                options={partners.filter(partner => !partner.is_lgu)} 
                                loading={partnersLoading}
                            />
                        )}
                    </Form.Item>
                    <Form.Item 
                        {...formItemLayout}
                        label=" "
                    >
                        <List 
                            header={<div>List of Partners</div>}
                            itemLayout={"horizotal"}
                            locale={{ emptyText: "No partner/s selected" }}
                            dataSource={!partnersInput ? [] : partners.filter(partner => partnersInput.includes(partner.id))}
                            renderItem={item => (
                                <List.Item
                                    actions={[
                                        <a 
                                            key="list-loadmore-edit"
                                            onClick={() => _removePartner(item.id)}
                                        >
                                            Remove
                                        </a>,
                                    ]}
                                >
                                    <List.Item.Meta 
                                        avatar={<Avatar  src={API.get_base() + '/' + item.logo} />}
                                        title={<p>{item.name}</p>}
                                    />
                                    <PartnerTag tag={item} />
                                </List.Item>
                            )}
                        />
                    </Form.Item>
                    <h1>Contact Details</h1>
                    <FormItem {...formItemLayout} label="Contact Person" >
                        {getFieldDecorator('contact_person', {
                            rules: [{
                                required: false, message: 'Please input!',
                            }],
                            initialValue: get(event, 'contact_person')
                        })(
                            <Input />
                        )}
                    </FormItem>
                    <FormItem {...formItemLayout} label="Contact Number" >
                        {getFieldDecorator('contact_number', {
                            rules: [{
                                required: false, message: 'Please input!',
                            }],
                            initialValue: get(event, 'contact_number')
                        })(
                            <Input />
                        )}
                    </FormItem>
                    <FormItem {...formItemLayout} label="Email" >
                        {getFieldDecorator('email', {
                            rules: [{
                                required: false, message: 'Please input!',
                            }],
                            initialValue: get(event, 'email')
                        })(
                            <Input />
                        )}
                    </FormItem>
                    <br />
                    <FormItem style={{ textAlign: 'center' }}>
                        <Button 
                            onClick={_handleSubmit} 
                            loading={submitting} 
                            type="primary" 
                            style={{ width: '40%' }}
                        >
                            Submit
                        </Button>
                    </FormItem>
                </Form>
            </div>
            {
                showTrashCollection && (
                    <React.Fragment>
                        <h3>Registrations</h3>
                        <RegistrationsList items={registrations} />
                        <h3>Trash Collection</h3>
                        <Button
                            type='primary'
                            onClick={() => history.push('/event/trash/' + match.params.id)}
                        >
                            Show Trash Collection
                        </Button>
                    </React.Fragment>
                )
            }
        </div>
    );
})

// const EventWithRef = React.forwardRef((props, ref) => <Event innerRef={ref} {...props} />);
export default Form.create()(Event);

const PartnerTag = ({ tag }) => {
    if (!tag.priority) {
        return null;
    }
    return (
        <Tag 
            color={tag.priority === "gold" ? "gold" : tag.priority === "silver" ? "#C0C0C0" : "#CD7F32" }
            style={{
                marginRight: "20vw"
            }}
        >
            {tag.priority.charAt(0).toUpperCase() + tag.priority.slice(1)}
        </Tag>
    );
}