import React, { Component } from 'react';
import { Table } from 'antd';

var DEFAULT_STATE = {
    items: []
}

class ItemsList extends Component {
    constructor(props) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    componentDidMount() {
        this.setState({ items: this.props.items });
    }

    render() {
        const columns = [

            {
                title: 'Name',
                key: 'name',
                render: (text, record) => (
                    <span style={{ fontWeight: 'bold' }}>
                        {record.name}
                    </span>
                )
            }, 
            {
                title: 'Date',
                dataIndex: 'created_at'
            },

            // {
            //     title: 'Actions',
            //     key: 'action',
            //     render: (text, record, index) => {
            //         return <span>
            //             <a onClick={this._deleteItem.bind(this, index)}>Delete</a>&nbsp;&sdot;&nbsp;
            //             <a onClick={this._openForm.bind(this, index, record)}>Update</a></span>

            //     }
            // }
        ]
        var divTable;
        divTable = <Table size='small' pagination={{ pageSize: 20 }} columns={columns} dataSource={this.props.items} />
        if (this.state.items && this.state.items.length === 0) {
            divTable = <span>No registrations yet</span>
        }
        return (
            <div>

                {divTable}

            </div>
        )
    }
}

export default ItemsList;
