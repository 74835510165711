import React from "react";

/**
 * only a rollup file for the components
 * rendered in Partner.js
 */

import AddPerksButton from "./AddPerksButton";
import RenderPromosList from "./RenderPromosList";

export default ({ formItemLayout, match, state, promos, images, logo, history }) => (
  <React.Fragment>
    <AddPerksButton
      formItemLayout={formItemLayout}
      match={match}
      state={state}
      promos={promos}
      images={images}
      logo={logo}
    />
    <RenderPromosList
      formItemLayout={formItemLayout}
      match={match}
      state={state}
      promos={promos}
      images={images}
      logo={logo}
      history={history}
    />
  </React.Fragment>
);
