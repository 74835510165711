import React from "react";
import { Button } from "antd";

export default ({ onSave = () => "", match = {}, onCancel = () => "" }) => (
  <div style={{ display: 'flex', flexDirection: 'row'}}>
    <div style={{ flex: 1 }}>
      <h1>Add Perk</h1>
    </div>
    <div style={{ flexDirection: 'row'}}>
      <Button type="primary" style={{ margin: "4px" }} onClick={onSave}>
        Save
      </Button>
      <Button style={{ margin: "4px" }} onClick={onCancel}>
        Cancel
      </Button>
    </div>
  </div>
);
