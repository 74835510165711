import React from "react";
import { Icon, Upload, Form } from "antd";
import { formItemLayout } from "../const";
import API from "../../../../../../services/APIService";

const uploadButton = (
  <div>
    <Icon type={"plus"} />
    <div className="ant-upload-text">Upload</div>
  </div>
);

export default ({ state, removeFile, onFileUpload, getFieldDecorator }) => {
  const image = state.image;
  const uploadProps = {
    accept: "image/*",
    action: API.image_upload_endpoint(),
    multiple: false,
    headers: API.token_header(),
    data: {},
    onChange: onFileUpload
  };

  return (
    <Form.Item label="Image" {...formItemLayout}>
      {getFieldDecorator("image", {
        rules: [
          {
            required: true
          }
        ],
        initialValue: state.image
      })(
        <Upload
          listType="picture-card"
          showUploadList={false}
          {...uploadProps}
          onRemove={() => removeFile({ image: undefined, loadingImage: false })}
        >
          {image ? (
            <img
              src={`${API.get_base()}/image?file_loc=${image}`}
              style={{ width: "20vw" }}
              alt='perks'
            />
          ) : (
            uploadButton
          )}
        </Upload>
      )}
    </Form.Item>
  );
};
