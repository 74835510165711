import React, { Component } from "react";
import { Form, message } from "antd";

import PerksFormHeader from "./Header";
import NameInput from "./NameInput";
import MechanicsInput from "./MechanicsInput";
import PromoSelectionInput from "./PromoSelectionInput";
import ImageUploadInput from "./ImageUploadInput";

class AddPerksForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      image: undefined,
      name: "",
      mechanics: "",
      for_passport: 1
    };

    this.onFileUpload = this.onFileUpload.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    const { location, match } = this.props;

    /**
     * this.props.location.state has a value passed
     * if it was accessed via addperksbutton
     *
     * Partner.js fetches an api call everytime
     * it mounts to populate its forms, so any data
     * is lost when navigating to other pages
     *
     * If this.props.location.state is empty,
     * we need to redirect back to the partner page.
     * This is needed since by design, this form
     * relies on the data fetched and passed by
     * Partner.js to 'rehydrate' the state of Partner.js
     */
    if (!location.state) {
      const locationObj = {
        pathname: `/partner/${match.params.id ? match.params.id : "new"}`
      };
      this.props.history.push(locationObj);
    } else if (location.state && match.params.promoId !== "new") {
      const promo = { ...location.state.promos[match.params.promoId] };

      this.setState({
        image: promo.image,
        name: promo.name,
        mechanics: promo.mechanics,
        for_passport: promo.for_passport
      });
    }
  }

  onSave() {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if ((err && !this.state.image) || this.state.image === "") {
        return;
      } else {
        /**
         * This component receives the state of the
         * partner where it was called, this is to
         * preserve changes in that state, since
         * partner.js calls an api at mount
         * to fill up its forms
         */
        const { location, match } = this.props;
        const passedState = location.state;
        /**
         * some promos in the db may not have promos
         */
        let promos = [];

        if (match.params.promoId === "new") {
          promos = passedState.promos ? passedState.promos : [];
        } else {
          promos = [
            ...location.state.promos.slice(0, match.params.promoId),
            ...location.state.promos.slice(Number(match.params.promoId) + 1)
          ];
        }

        /**
         * if we save, were going to add the
         * current state into the promos array
         * of the partner data
         */
        const state = this.state;
        /**
         * locationObj.state is going to pass
         * the state of partner that was passed
         * to this component, so it can be used
         * to rehydrate Partner.js if any changes
         * was made before going to this form
         */
        const locationObj = {
          pathname: `/partner/${match.params.id ? match.params.id : "new"}`,
          state: {
            ...passedState,
            promos: [...promos, { ...state }]
          }
        };

        this.props.history.push(locationObj);
      }
    });
  }

  onCancel() {
    /**
     * functions the same way as
     * onSave() without altering
     * its promos
     */
    const { location, match } = this.props;
    const passedState = location.state;
    const locationObj = {
      pathname: `/partner/${match.params.id ? match.params.id : "new"}`,
      state: {
        ...passedState
      }
    };

    this.props.history.push(locationObj);
  }

  onFileUpload(e) {
    const isJpgOrPng = ["image/jpeg", "image/png"].includes(e.file.type);
    const fileUrl = e.file.response ? e.file.response.file_loc : "";
    if (isJpgOrPng && !e.event) {
      this.setState({ image: fileUrl });
    } else if (!isJpgOrPng) {
      message.error("You can only upload file types that end with .jpg/.png");
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <React.Fragment>
        <PerksFormHeader
          match={this.props.match}
          onSave={this.onSave}
          onCancel={this.onCancel}
        />
        <Form layout={"vertical"}>
          <NameInput
            state={this.state}
            setState={state => this.setState(state)}
            getFieldDecorator={getFieldDecorator}
          />
          <MechanicsInput
            state={this.state}
            setState={state => this.setState(state)}
            getFieldDecorator={getFieldDecorator}
          />
          <PromoSelectionInput
            state={this.state}
            setState={state => this.setState(state)}
            getFieldDecorator={getFieldDecorator}
          />
          <ImageUploadInput
            state={this.state}
            removeFile={this.setState}
            onFileUpload={this.onFileUpload}
            getFieldDecorator={getFieldDecorator}
          />
        </Form>
      </React.Fragment>
    );
  }
}

export default Form.create({ name: "addPerksForm" })(AddPerksForm);
