import React, { useMemo } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api'; 

const GoogleMapComponent = ({
    marker,
    containerStyle = {
        position: 'static',
        width: "100%",
        height: "400px"
    },
    onClick
}) => {
    const center = useMemo(() => ({ lat: 12.80, lng: 122 }), []);

    const options = {
        zoomControlOptions: {
          position: 8
        }
    }
      
    return (
        <GoogleMap
            mapContainerStyle={containerStyle}
            disableDefaultUI={true}
            zoom={!marker ? 5.2 : 10}
            center={!marker ? center : marker}
            zoomControl
            onClick={onClick}
            options={options}
        >
            {
                !!marker && (
                    <Marker position={marker} />
                )
            }
        </GoogleMap>
    );
}

export default GoogleMapComponent;