import React, { useEffect } from 'react';
import { Table, Spin } from 'antd';
import get from 'lodash/get';
import useEventTotalsGroup from '../hooks/useEventTotalsGroup';
import useEventCollectorsTotalsGroup from '../hooks/useEventCollectorsTotalsGroup';
import {
  ListHeader,
  ListHeaderTitle,
  TotalParent,
  TotalCard,
  TotalTitle,
  TotalCount
} from '../components';
import keys from 'lodash/keys';
import forEach from 'lodash/forEach';

const ByGroup = ({
    eventId,
    cleanUpType,
}) => {
  const [totalsFetching, totals = { groups: [], animals: 0}, updateTotalsParams] = useEventTotalsGroup({ 
    params: { 
      event_id: eventId, 
      filters: {
        type_of_cleanup: cleanUpType
      },
    },
  });
  const [collectorsFetching, collectors = { data: [] }, updateCollectorsParams] = useEventCollectorsTotalsGroup({ 
    params: { 
      event_id: eventId,
      page: 1,
      filters: {
        type_of_cleanup: cleanUpType
      }
    }
  });

  useEffect(() => {
    updateTotalsParams({
      event_id: eventId, 
      filters: {
        type_of_cleanup: cleanUpType
      },
    });
    updateCollectorsParams({
      event_id: eventId, 
      filters: {
        type_of_cleanup: cleanUpType
      },
      page: 1
    });
  }, [cleanUpType, eventId]);

  const onCollectorsTableChange = (pagination) => {
    updateCollectorsParams({
      event_id: eventId, 
      filters: {
        type_of_cleanup: cleanUpType
      },
      page: pagination.current
    });
  };
  
  let columns = []
  if (collectors.data.length > 0) {
    const cols = keys(collectors.data[0]);
    forEach(cols, col => {
      if (col !== 'id') {
        if (col === 'name') {
          columns.push({
            title: 'Name',
            dataIndex: 'name'
          });
        } else {
          columns.push({
            title: col,
            dataIndex: col
          });
        }
      }
    });
  }

  return (
    <React.Fragment>
        <TotalParent>
            {
                totalsFetching && (
                    <TotalCard>
                    <Spin />
                    </TotalCard>
                )
            }
            {
                totals.groups.map(group => (
                    <TotalCard key={group}>
                    <TotalTitle>{group.group}</TotalTitle>
                    <TotalCount>{group.total}</TotalCount>
                    </TotalCard>
                ))
            }
            <TotalCard>
                <TotalTitle>Animals Collected</TotalTitle>
                <TotalCount>{totals.animals}</TotalCount>
            </TotalCard>
        </TotalParent>
        <ListHeader>
            <ListHeaderTitle>Submitted Trash Collections:</ListHeaderTitle>
        </ListHeader>
        <Table 
            loading={collectorsFetching}
            dataSource={collectors.data} 
            columns={columns} 
            rowKey='id' 
            onChange={onCollectorsTableChange}
            pagination={{
              total: get(collectors, 'total') || 0,
              current: get(collectors, 'current_page') || 1,
              pageSize: get(collectors, 'per_page') || 20
            }}
        />
    </React.Fragment>
  );
}

export default ByGroup;