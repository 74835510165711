var AuthService = {
  set: function(data) {
    localStorage.setItem("user", JSON.stringify(data));
  },
  get: function() {
    return JSON.parse(localStorage.getItem("user"));
  },
  setToken: function(token) {
    localStorage.setItem("token", token);
  },
  getToken: function(data) {
    return localStorage.getItem("token");
  },
  clear: function() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  },
  isLoggedIn: function() {
    return localStorage.getItem("token") != null;
  },
  updateUser(data) {
    var user = JSON.parse(localStorage.getItem("user"));
    // TODO: update user based on data.
    localStorage.setItem("user", JSON.stringify(user));
  }
}
export default AuthService;
