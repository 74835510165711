import React, { Component } from 'react';
import { 
    message, 
    Spin, 
    Form, 
    Input, 
    Button 
} from 'antd';
import API from 'services/APIService';
import UploadFile from '../../../../components/UploadFile';

const FormItem = Form.Item;

var DEFAULT_STATE = {
    submit_loading: false,
    item_loading: false,
    item: {},
    title: 'New Type',
    form_sizes: [],
    form_images: []
}

const formItemLayout = {
    labelCol: {
        lg: { span: 6 },
    },
    wrapperCol: {
        lg: { span: 18 },
    },
};

class Wrapper extends Component {
    constructor(props) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    componentDidMount() {
        console.log("componentDidMount");
        this.setState(DEFAULT_STATE);
        if (this.props.match.params.id !== 'new') {
            this._loadData(this.props.match.params.id);
        }
        else {
            this.setState(DEFAULT_STATE);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            console.log("componentDidUpdate");
            if (this.props.match.params.id !== 'new') {
                this._loadData(this.props.match.params.id);
            }
            else {
                this.setState(DEFAULT_STATE);
            }
        }
    }

    _loadData = (id) => {
        this.setState({ item_loading: true });
        this.setState({ title: 'Edit Type' });
        API.type_read(id)
            .then((response) => {
                var data = response.data;
                this.setState({
                    item_loading: false,
                    item: data,
                    form_images: [data.image],
                });
            })
            .catch((error) => {
                if (error.response.data) { message.error(error.response.data.messages[0]); }
                else { message.error(error.message); }
                this.setState({ item_loading: false });
                console.log(error);
            })
    }

    _handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ submit_loading: true });
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                var payload = values;
                if (this.props.match.params.id === 'new') {
                    API.type_create(payload)
                        .then((response) => {
                            message.success('Type Created', 1);
                            this.setState({ submit_loading: false })
                            this.props.history.push('/types');
                            window.location.reload();
                        })
                        .catch((error) => {
                            if (error.response.data) { message.error(error.response.data.messages[0]); }
                            else { message.error(error.message); }
                            this.setState({ submit_loading: false });
                            console.log(error);
                        });
                }
                else {
                    API.type_update(this.props.match.params.id, payload)
                        .then((response) => {
                            message.success('Type Updated', 1);
                            this.setState({ submit_loading: false });
                            this.props.history.push('/types');
                        })
                        .catch((error) => {
                            if (error.response.data) { message.error(error.response.data.messages[0]); }
                            else { message.error(error.message); }
                            this.setState({ submit_loading: false });
                            console.log(error);
                        });
                }
            }
            else {
                message.error('Submit Error!', 4);
                this.setState({ submit_loading: false });
                console.log(err);
            }
        });

    }

    render() {
        if (this.state.item_loading) {
            return <div style={{ textAlign: 'center' }}><Spin size='large' style={{ margin: '150px' }} /></div>
        }
        const { getFieldDecorator } = this.props.form;
        var divForm = <Form onSubmit={this._handleSubmit} layout='vertical'>

            <FormItem {...formItemLayout} label="Type" >
                {getFieldDecorator('type', {
                    rules: [{
                        required: true, message: 'Please input!',
                    }],
                    initialValue: this.state.item.type
                })(
                    <Input />
                )}
            </FormItem>

            <FormItem 
                {...formItemLayout} 
                label="Image" 
            >
                {
                    getFieldDecorator(
                        'image', 
                        { 
                            rules: [{ required: true, message: 'Please input!', }],
                            initialValue: this.state.item.image
                        }
                    )(
                        <UploadFile />
                    )
                }
            </FormItem>

            {/* <FormItem {...formItemLayout} label="Image" >
                <ImageGroup span={8} limit={1} images={this.state.form_images} ref={(imagesEditor) => { this.imagesEditor = imagesEditor; }} />

            </FormItem> */}


            <FormItem style={{ textAlign: 'center' }}>
                <Button loading={this.state.loading} type="primary" htmlType="submit" className="login-form-button" style={{ width: '40%' }}>
                    Submit
                </Button>
            </FormItem>
        </Form>


        return (
            <div>
                <h1>{this.state.title}</h1>
                {divForm}
            </div>
        )
    }
}

const Type = Form.create()(Wrapper);
export default Type;
