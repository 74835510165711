import React from 'react';
import { Select, Spin } from 'antd';

const Option = Select.Option;

export default React.forwardRef(({
  options = [],
  placeholder = 'Please select partner/s',
  value,
  onChange,
  loading = false
}, _) => {
  const handleChange = (value) => {
    if (onChange) {
      onChange(value.map(val => parseInt(val)));
    }
  }

  return (
    <span>
      <Select
        value={value}
        mode='multiple'
        placeholder={placeholder}
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={handleChange}
        loading={loading}
      >
        {
          options.map((partner) => <Option value={partner.id} key={partner.id.toString()}>{partner.name}</Option>)
        }
      </Select>
    </span>
  );
})