import React, {Component} from 'react';
import { NavLink } from 'react-router-dom';
import { get } from 'lodash';
import { Table, Icon, Form, Button, Modal, message, Input, Radio } from 'antd';
import API from 'services/APIService';
import ApproveMembershipModal from './components/ApproveMembershipModal';

const FormItem = Form.Item;
const confirm = Modal.confirm;
class UserListForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: null,
			showApprove: undefined
    };
  }

  _handleDisable = (value) => {
    confirm({
      title: 'Are you sure?',
      content: 'Disable User.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        this.setState({ loading: true });
        API.user_disable(value.id)
          .then((response) => {
            this._refreshList();
          })
          .catch((error) => {
            if (error.response.data) {
              message.error(error.response.data.messages[0]);
            }
            else {
              message.error(error.message);
            }
            this.setState({ loading: false });
            console.log(error);
          })
      },
      onCancel() {
        console.log('Cancel');
      }
    });
  }

  _handleEnable = (value) => {
    confirm({
      title: 'Are you sure?',
      content: 'Re-enable User.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        this.setState({ loading: true });
        API.user_enable(value.id)
          .then((response) => {
            this._refreshList();
          })
          .catch((error) => {
            if (error.response.data) {
              message.error(error.response.data.messages[0]);
            }
            else {
              message.error(error.message);
            }
            this.setState({ loading: false });
            console.log(error);
          })
      },
      onCancel() {
        console.log('Cancel');
      }
    });
	}

  _handleApprove = (value) => {
		this.setState({ showApprove: value });
  }

  _handleDisapprove = (value) => {
    confirm({
      title: 'Are you sure?',
      content: 'Disapprove User.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        this.setState({ loading: true });
        API.user_disapprove(value.id)
          .then((response) => {
            this._refreshList();
          })
          .catch((error) => {
            if (error.response.data) {
              message.error(error.response.data.messages[0]);
            }
            else {
              message.error(error.message);
            }
            this.setState({ loading: false });
            console.log(error);
          })
      },
      onCancel() {
        console.log('Cancel');
      }
    });
  }

  _handleSubmit = (e) => {
    e.preventDefault();
    this.setState({loading: true});
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        this._loadData(values.search);
      }
      else {
        message.error('Error!', 5);
        this.setState({loading: false});
      }
    })
  }

  componentDidMount() {
    this._loadData('');
  }

  _loadData = (q) => {
    API.user_list(q)
      .then((response) => {
        var data = response.data.data;
        this.setState({loading: false, data: data})
      })
      .catch((error) => {
        const errorMessage = get(error, 'response.data.messages[0]', '')
        if (errorMessage){
          message.error(errorMessage);
        }
        else {
          message.error(error.message);
        }
        this.setState({ loading: false});
        console.log(error);
      })
  }

  _refreshList = () => {
    this.setState({loading: true});
    this._loadData();
  }

  columns = [
    {
      title: 'Name',
      key: 'name',
      render: (text, record) => (
        <span style={{fontWeight: 'bold'}}>
          {record.name}
        </span>
      )
    },
    {
      title: 'Email',
      dataIndex: 'email'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      filters: [
        {
          text: 'admin',
          value: 'admin'
        },
        {
          text: 'user',
          value: 'user'
        },
      ],
      onFilter: (value, record) => {
        return record.type.indexOf(value) === 0
      }
    },
    {
      title: 'Status',
      key: 'status',
      render: (text, record) => {
        if(record.is_disabled === true) {
          return<span>Disabled</span>
        }
        else if (record.type === 'admin') {
          return <span>Active admin</span>
        }
        else if (record.is_verified === false) {
          return <span>Pending verification</span>
        }
        else if (record.is_approved === false && record.is_applying === true) {
          return <span>Pending approval</span>            
        }
        else if (record.is_approved === true) {
          return <span>Membership approved</span>
        }
        else {
          return <span>Volunteer</span>
        }
      },
      filters: [
        {
          text: 'Disabled',
          value: 'disabled'
        },
        {
          text: 'Active admin',
          value: 'active_admin'
        },
        {
          text: 'Pending verification',
          value: 'pending_verification'
        },
        {
          text: 'Pending approval',
          value: 'pending_approval'
        },
        {
          text: 'Membership approved',
          value: 'membership_approved'
        },
        {
          text: 'Non-member',
          value: 'non_member'
        },
      ],
      onFilter: (value, record) => {
        if (record.is_disabled === true && value === 'disabled') {
          return true;
        }
        else if (record.type === 'admin' && value === 'active_admin') {
          return true;
        }
        else if (record.is_verified === false && value === 'pending_verification') {
          return true;
        }
        else if (record.is_approved === false && record.is_applying === true && value === 'pending_approval') {
          return true;
        }
        else if (record.is_applying === true && value === 'membership_approved') {
          return true;
        }
        else if (value === 'non_member') {
          return true;
        }          
        else {
          return false;
        }
      }
    },
    {
      title: 'Actions',
      key: 'action',
      render: (text, record) => {
        var extraButtons;
        if (record.is_disabled === true) {
          // enable
          extraButtons = <span>&nbsp;&sdot;&nbsp;<a onClick={this._handleEnable.bind(this, record)}>Enable</a></span>;
        }
        else if (record.type === 'admin') {
          // disable
          extraButtons = <span>&nbsp;&sdot;&nbsp;<a onClick={this._handleDisable.bind(this, record)}>Disable</a></span>;
        }
        else if (record.is_verified === false) {
          // disable
          extraButtons = <span>&nbsp;&sdot;&nbsp;<a onClick={this._handleDisable.bind(this, record)}>Disable</a></span>;
        }
        else if (record.is_approved === false && record.is_applying === true) {
          // approve
          extraButtons = <span>&nbsp;&sdot;&nbsp;<a onClick={this._handleApprove.bind(this, record)}>Approve</a></span>;
        }
        else if (record.is_approved === true) {
          // disapprove / disable
          extraButtons = <span>&nbsp;&sdot;&nbsp;<a onClick={this._handleDisapprove.bind(this, record)}>Disapprove</a>
            &nbsp;&sdot;&nbsp;<a onClick={this._handleDisable.bind(this, record)}>Disable</a></span>;
        }
        else {
          // disable
          extraButtons = <span>&nbsp;&sdot;&nbsp;<a onClick={this._handleDisable.bind(this, record)}>Disable</a></span>;
        }
        return <span>
          <NavLink to={'/user/' +record.id}>View</NavLink>
          {extraButtons}
          </span>
      }
    }
  ];

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <h1>
          Users <Button style={{float: 'right'}} type='primary' icon='plus' href='/user/new'>New User</Button>
        </h1>
        <Form onSubmit={this._handleSubmit} className="login-form" style={{ width: '350px' }}>
          <FormItem>
            {getFieldDecorator('search', {
              rules: [{ required: false, message: 'Search users' }],
            })(
              <Input prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Search users" />
            )}
          </FormItem>
        </Form>
        <Table 
          size='small' 
          loading={this.state.loading} 
          pagination={{pageSize: 20}} 
          columns={this.columns} 
          dataSource={this.state.data} 
          rowKey='id'
        />
        <ApproveMembershipModal
          user={this.state.showApprove}
          visible={!!this.state.showApprove}
          onCancelPress={() => this.setState({ showApprove: undefined })}
          onApproveSuccess={() => { 
            this.setState({ showApprove: undefined })
            this._refreshList();
          }}
        />
      </div>
    )
  }
}

const UserList = Form.create()(UserListForm);

export default UserList;
