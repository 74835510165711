import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import { Layout, Menu } from 'antd';

import AuthService from 'services/AuthService';

import ViewLogin from './ViewLogin';

const { Header, Content, Footer } = Layout;

class Landing extends Component {
  render() {
    if(AuthService.isLoggedIn()) {
      this.props.history.push('/');
    }
    return (
      <Layout className="layout" style={{ background: '#e8f4fe' }}>
        <Header style={{ position: 'fixed', width: '100%', backgroundColor: "#fff", borderBottom: "0px solid rgb(238, 238, 238)" }}>
          <div className="logo" />
          <Menu
            theme="light"
            mode="horizontal"
            defaultSelectedKeys={['1']}
            style={{ lineHeight: '64px', float: 'right' }}
          >
          </Menu>
        </Header>
        <Content style={{ padding: '50px 50px 0px 50px', marginTop: '50px' }}>
          <Switch>
            <Route path="/login" name="Login" component={ViewLogin} />
            <Route path="/logout" name="Logout" component={Logout} />
          </Switch>
        </Content>
        <Footer style={{ textAlign: 'center', background: '#e8f4fe'  }}>
          Shore It Up! © 2018
        </Footer>
      </Layout>
    )
  }
}

class Logout extends Component {
  render() {
    AuthService.clear();
    this.props.history.push('/login');
    return <div></div>
  }
}
export default Landing;
