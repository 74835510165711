import React, { Component } from 'react';
import API from 'services/APIService';
import { 
    message, 
    List, 
    Avatar, 
    Tag,
    Spin,
    Form, 
    Input,
    Select,
    Button
} from 'antd';
import ImageGroup from '../ImageGroup';
import GoogleMapPlaces from './GoogleMapPlaces';

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

var DEFAULT_STATE = {
    submit_loading: false,
    item_loading: false,
    item: {
        images: []
    },
    title: 'New Site',
    form_contact_persons: [],
    position: undefined, // { lat: 14.80, lng: 121 },
    type_options: [],
    partner_data: []
}

const formItemLayout = {
    labelCol: {
        lg: { span: 6 },
    },
    wrapperCol: {
        lg: { span: 18 },
    },
};
class Wrapper extends Component {
    constructor(props) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    componentDidMount() {
        console.log("componentDidMount");
        this.setState(DEFAULT_STATE);
        if (this.props.match.params.id !== 'new') {
            this._loadData(this.props.match.params.id);
        }
        else {
            this._loadTypes();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            console.log("componentDidUpdate");
            if (this.props.match.params.id !== 'new') {
                this._loadData(this.props.match.params.id);
            }
            else {
                this._loadTypes();

            }
        }
    }

    _loadTypes = () => {
        this.setState({ item_loading: true });
        API.type_list()
            .then((response) => {
                var data = response.data.data;
                this.setState({
                    type_options: data,
                    item_loading: false
                });
            })
            .catch((error) => {
                if (error.response.data) { message.error(error.response.data.messages[0]); }
                else { message.error(error.message); }
                this.setState({ item_loading: false });
                console.log(error);
            })

        API.partner_list()
            .then((response) => {
                console.log(response.data);
                var data = response.data.data;
                this.setState({ init_loading: false, loading: false, partner_data: data })
            })
            .catch((error) => {
                if (error.response.data) {
                    message.error(error.response.data.messages[0]);
                }
                else {
                    message.error(error.message);
                }
                this.setState({ loading: false });
                console.log(error);
            })
    }

    _loadData = (id) => {
        this.setState({ item_loading: true });
        this.setState({ title: 'Edit Site' });
        API.site_read(id)
            .then((response) => {
                var data = response.data;
                this.setState({
                    item_loading: false,
                    item: data,
                    form_contact_persons: data.contact_persons,
                    position: {lat: data.latitude, lng: data.longitude},
                });
                return API.type_list();
            })
            .then((response) => {
                var data = response.data.data;
                this.setState({
                    type_options: data,
                    item_loading: false
                });
            })
            .catch((error) => {
                if (error.response.data) { message.error(error.response.data.messages[0]); }
                else { message.error(error.message); }
                this.setState({ item_loading: false });
                console.log(error);
            })

        API.partner_list()
            .then((response) => {
                console.log(response.data);
                var data = response.data.data;
                this.setState({ init_loading: false, loading: false, partner_data: data })
            })
            .catch((error) => {
                if (error.response.data) {
                    message.error(error.response.data.messages[0]);
                }
                else {
                    message.error(error.message);
                }
                this.setState({ loading: false });
                console.log(error);
            })
    }

    _handleSubmit = () => {
        //e.preventDefault();
        this.setState({ submit_loading: true });
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                var payload = values;
                payload.latitude = this.state.position.lat;
                payload.longitude = this.state.position.lng;

                // create custom payload
                payload.images = this.imagesEditor.state.images;

                // antd.select component needs values in the form of strings
                // so we convert them back to integers
                payload.lgu_partners = payload.lgu_partners.map(
                    lgu_partner => Number(lgu_partner)
                );
                payload.partners = payload.partners.map(
                    partner => Number(partner)
                );

                // payload.contact_persons = this.contact_persons.state.contact_persons;
                // end create custom payload
                if (this.props.match.params.id === 'new') {
                    API.site_create(payload)
                        .then((response) => {
                            message.success('Site Created', 1);
                            this.setState({ submit_loading: false })
                            this.props.history.push('/sites');
                            window.location.reload();
                        })
                        .catch((error) => {
                            if (error.response.data) { message.error(error.response.data.messages[0]); }
                            else { message.error(error.message); }
                            this.setState({ submit_loading: false });
                            console.log(error);
                        });
                }
                else {
                    API.site_update(this.props.match.params.id, payload)
                        .then((response) => {
                            message.success('Site Updated', 1);
                            this.setState({ submit_loading: false });
                            this.props.history.push('/sites');
                        })
                        .catch((error) => {
                            if (error.response.data) { message.error(error.response.data.messages[0]); }
                            else { message.error(error.message); }
                            this.setState({ submit_loading: false });
                            console.log(error);
                        });
                }
            }
            else {
                message.error('Submit Error!', 4);
                this.setState({ submit_loading: false });
                console.log(err);
            }
        });

    }

    _setSelectedPosition = (position) => {
        this.setState({ position });
    }

    removePartner = (id) => {
        const { getFieldValue, setFieldsValue } = this.props.form;
        const partners = getFieldValue("partners");
        const filteredPartners = partners.filter( partner => 
            String(id) !== partner
        )
        setFieldsValue({ partners: filteredPartners })
    }

    renderTag = (item) => {
        if (!item.priority) {
            return ""
        }
        return (
            <Tag 
                color={
                    item.priority === "gold" ? "gold" :
                    item.priority === "silver" ? "#C0C0C0" :
                    "#CD7F32"
                }
                style={{
                    marginRight: "20vw"
                }}
            >
                {item.priority.charAt(0).toUpperCase() + item.priority.slice(1)}
            </Tag>
        )
    }

    render() {
        if (this.state.item_loading) {
            return <div style={{ textAlign: 'center' }}><Spin size='large' style={{ margin: '150px' }} /></div>
        }
        const { getFieldDecorator, getFieldValue } = this.props.form;
        var divForm = <div><Form layout='vertical'>

            <FormItem {...formItemLayout} label="Name" >
                {getFieldDecorator('name', {
                    rules: [{
                        required: true, message: 'Please input!',
                    }],
                    initialValue: this.state.item.name
                })(
                    <Input />
                )}
            </FormItem>
            <FormItem {...formItemLayout} label="Type" >
                {getFieldDecorator('type_id', {
                    rules: [{
                        required: true, message: 'Please input!',
                    }],
                    initialValue: this.state.item.type_id
                })(
                    <Select>
                        {
                            this.state.type_options.map(function (item) {
                                return <Option value={item.id} key={item.id}>{item.type}</Option>
                            })
                        }
                    </Select>

                )}
            </FormItem>
            <FormItem {...formItemLayout} label="Site Images" >
                <ImageGroup limit={10} span={8} images={this.state.item.images} ref={(imagesEditor) => { this.imagesEditor = imagesEditor; }} />
            </FormItem>

            {/* <FormItem {...formItemLayout} label="Address" >
                {getFieldDecorator('address', {
                    rules: [{
                        required: true, message: 'Please input!',
                    }],
                    initialValue: this.state.item.address
                })(
                    <Input />
                )}
            </FormItem> */}
            
            <GoogleMapPlaces
                form={this.props.form}
                formItemLayout={formItemLayout}
                setSelected={this._setSelectedPosition}
                selected={this.state.position}
                address={this.state.item.address}
            />
           
            <FormItem {...formItemLayout} label="Site Details" >
                {getFieldDecorator('full_description', {
                    rules: [{
                        required: true, message: 'Please input!',
                    }],
                    initialValue: this.state.item.full_description ? this.state.item.full_description: ''
                })(
                    <TextArea rows={5}
                    />
                )}
            </FormItem>
            <FormItem {...formItemLayout} label="Contact Person" >
                {getFieldDecorator('contact_person', {
                    rules: [{
                        required: true, message: 'Please input!',
                    }],
                    initialValue: this.state.item.contact_person
                })(
                    <Input />
                )}
            </FormItem>
            <FormItem {...formItemLayout} label="Contact Number" >
                {getFieldDecorator('contact_number', {
                    rules: [{
                        required: true, message: 'Please input!',
                    }],
                    initialValue: this.state.item.contact_number
                })(
                    <Input />
                )}
            </FormItem>
            <Form.Item 
                {...formItemLayout}
                label="LGU Partners"
            >
                {getFieldDecorator('lgu_partners', {
                    initialValue: this.state.item.lgu_partners
                        ? this.state.item.lgu_partners.map(partner => String(partner.id))
                        : []
                })(
                    <Select mode="multiple" placeholder="Please select LGU partner/s">
                        {
                            this.state.partner_data.filter(partner => 
                                partner.is_lgu === 1
                            ).map(partner =>
                                <Option value={String(partner.id)} key={partner.id}>{partner.name}</Option>
                            )
                        }
                    </Select>
                )}
            </Form.Item>
            <Form.Item 
                {...formItemLayout}
                label="Partners"
            >
                {getFieldDecorator('partners', {
                    initialValue: this.state.item.partners
                        ? this.state.item.partners.map(partner => String(partner.id))
                        : []
                })(
                    <Select mode="multiple" placeholder="Please select Partner/s">
                        {
                            this.state.partner_data.filter(partner => 
                                partner.is_lgu === 0
                            ).map(partner =>
                                <Option value={String(partner.id)} key={partner.id}>{partner.name}</Option>
                            )
                        }
                    </Select>,
                )}
            </Form.Item>

            <Form.Item 
                {...formItemLayout}
                label=" "
            >
                <List 
                    header={<div>List of Partners</div>}
                    itemLayout={"horizotal"}
                    locale={{ emptyText: "No partner/s selected" }}
                    dataSource={
                        this.state.partner_data.filter(partner => 
                            // 
                            getFieldValue("partners").includes(String(partner.id))
                        )
                    }
                    renderItem={ item => 
                        <List.Item
                            actions={[
                                <a 
                                    key="list-loadmore-edit"
                                    onClick={() => this.removePartner(item.id)}
                                >Remove</a>,
                            ]}
                        >
                            <List.Item.Meta 
                                avatar={<Avatar 
                                    src={API.get_base() + '/' + item.logo}
                                />}
                                title={<p>{item.name}</p>}
                            />
                                {
                                   this.renderTag(item) 
                                }
                        </List.Item>
                    }
                />
            </Form.Item>
       </Form>
        {
                // <ContactList contact_persons={this.state.form_contact_persons} ref={(contact_persons) => { this.contact_persons = contact_persons; }} />
        }
            <br />
            <FormItem style={{ textAlign: 'center' }}>
                <Button onClick={this._handleSubmit} loading={this.state.submit_loading} type="primary" style={{ width: '40%' }}>
                    Submit
                </Button>
            </FormItem>
        </div>


        return (
            <div>
                <h1>{this.state.title}</h1>
                {divForm}
            </div>
        )
    }
}

const Site = Form.create()(Wrapper);
export default Site;
