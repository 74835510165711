import React, { Component } from 'react';
import {
  Spin,
  Radio,
  Switch as AntdSwitch,
  Form,
  Input,
  Button,
  message
} from "antd";
import API from 'services/APIService';

import ImageGroup from '../ImageGroup';

/**
 * imports a rollup file
 */
import AddPerks from './AddPerks'

const FormItem = Form.Item;

var DEFAULT_STATE = {
    submit_loading: false,
    item_loading: false,
    item: {
        images: []
    },
    title: 'New Partner',
    form_promos: [],
    logo: []

}

const formItemLayout = {
    labelCol: {
        lg: { span: 6 },
    },
    wrapperCol: {
        lg: { span: 18 },
    },
};

class Wrapper extends Component {
    constructor(props) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    componentDidMount() {
        console.log("componentDidMount", this.props);
        // this.setState(DEFAULT_STATE);
        if (this.props.match.params.id !== 'new') {
            this._loadData(this.props.match.params.id);
            this.usePassedState();
        }
        else {
            this.setState(DEFAULT_STATE);
            this.usePassedState();
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            console.log("componentDidUpdate");
            if (this.props.match.params.id !== 'new') {
                this._loadData(this.props.match.params.id);
            }
            else {
                this.setState(DEFAULT_STATE);
            }
        }

   }

   _removePromo = promoIndex => {
        const location = this.props.location
        const promos = location.promos
        const filteredPromos = promos.filter(
            (promo ,index) => promoIndex === index
        );
        const newLocation = {
            ...location,
            promos: filteredPromos
        }

        this.props.history.replace(newLocation);
   }

   usePassedState = () => {
        /**
         * Checks if Partner.js was accessed
         * via the save button on AddPerksForm.js
         * 
         * If state is defined, we will used it
         * to 'state' the state of this component
         */
        if (this.props.location.state) {
            const { setFieldsValue } = this.props.form
            const passedState = this.props.location.state
            /**
             * since there's no field named promos
             * we pass it as a payload in handlesubmit
             * instead
             */
            this.setState(prevState => ({
                logo: passedState.logo,
                item: {
                    ...prevState.item,
                    images: passedState.images,
                }
            }))

            setFieldsValue({
                name: passedState.name,
                contact_information: passedState.contact_information,
                address: passedState.address,
                priority: passedState.priority,
                is_lgu: passedState.is_lgu,
            })
        }
   }

    _loadData = (id) => {
        this.setState({ item_loading: true });
        this.setState({ title: 'Edit Partner' });
        API.partner_read(id)
            .then((response) => {
                var data = response.data;
                const passedState = this.props.location.state
                    ? this.props.location.state
                    : data
                const itemData = {
                    ...response.data,
                    name: passedState.name,
                    contact_information: passedState.contact_information,
                    address: passedState.address
                }
                this.setState({
                    item_loading: false,
                    item: itemData,
                    form_promos: data.promos,
                    logo: [data.logo]
                }, console.log(response.data));

                this.usePassedState();
            })
            .catch((error) => {
                if (error.response.data) { message.error(error.response.data.messages[0]); }
                else { message.error(error.message); }
                this.setState({ item_loading: false });
                console.log(error);
            })
    }

    _handleSubmit = () => {
        //e.preventDefault();
        this.setState({ submit_loading: true });
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                var payload = values;
                // create custom payload
                payload.images = this.imagesEditor.state.images;
                payload.logo = this.logoEditor.state.images[0];
                const serverSavedPromos = this.state.item.promos 
                    ? this.state.item.promos 
                    : []
                payload.promos = this.props.location.state 
                    ? this.props.location.state.promos 
                    : serverSavedPromos
                payload.is_lgu  = payload.is_lgu;
                payload.priority  = payload.is_lgu ? undefined : payload.priority;
                // end create custom payload
                if (this.props.match.params.id === 'new') {
                    API.partner_create(payload)
                        .then((response) => {
                            message.success('Partner Created', 1);
                            this.setState({ submit_loading: false })
                            this.props.history.push('/partners');
                            window.location.reload();
                        })
                        .catch((error) => {
                            if (error.response.data) { message.error(error.response.data.messages[0]); }
                            else { message.error(error.message); }
                            this.setState({ submit_loading: false });
                            console.log(error);
                        });
                }
                else {
                    API.partner_update(this.props.match.params.id, payload)
                        .then((response) => {
                            message.success('Partner Updated', 1);
                            this.setState({ submit_loading: false });
                            this.props.history.push('/partners');
                        })
                        .catch((error) => {
                            if (error.response.data) { message.error(error.response.data.messages[0]); }
                            else { message.error(error.message); }
                            this.setState({ submit_loading: false });
                            console.log(error);
                        });
                }
            }
            else {
                message.error('Submit Error!', 4);
                this.setState({ submit_loading: false });
                console.log(err);
            }
        });

    }

    render() {
        if (this.state.item_loading) {
            return <div style={{ textAlign: 'center' }}><Spin size='large' style={{ margin: '150px' }} /></div>
        }
        const { getFieldDecorator, getFieldsValue, getFieldValue } = this.props.form;
        const serverSavedPromos = this.state.item.promos ? this.state.item.promos : []
        const passedStatePromos = this.props.location.state ? this.props.location.state.promos : []
        // if location.state is present, it means the serverSavedPromos promos has already been merged once
        const promos = this.props.location.state 
            // no location.state and submit
            ? passedStatePromos
            : serverSavedPromos 
        
        var divForm = <div><Form onSubmit={this._handleSubmit} layout='vertical'>

            <FormItem {...formItemLayout} label="Name" >
                {getFieldDecorator('name', {
                    rules: [{
                        required: true, message: 'Please input!',
                    }],
                    initialValue: this.state.item.name
                })(
                    <Input />
                )}
            </FormItem>
            <FormItem {...formItemLayout} label="Logo" required={true}>
                <ImageGroup limit={1} span={8} images={this.state.logo} ref={(logoEditor) => { this.logoEditor = logoEditor; }} />
            </FormItem>
            
            <FormItem {...formItemLayout} label="Partner Images" required={true}>
                <ImageGroup limit={10} span={8} images={this.state.item.images} ref={(imagesEditor) => { this.imagesEditor = imagesEditor; }} />
            </FormItem>


            <FormItem {...formItemLayout} label="Address" >
                {getFieldDecorator('address', {
                    rules: [{
                        required: true, message: 'Please input!',
                    }],
                    initialValue: this.state.item.address
                })(
                    <Input />
                )}
            </FormItem>
            <FormItem {...formItemLayout} label="Contact Information" >
                {getFieldDecorator('contact_information', {
                    rules: [{
                        required: true, message: 'Please input!',
                    }],
                    initialValue: this.state.item.contact_information
                })(
                    <Input />
                )}
            </FormItem>
            <FormItem {...formItemLayout} label="Priority Tag" >
                <div style={{ marginButton: "10px"}}>
                    {getFieldDecorator('is_lgu', {
                        rules: [{
                            required: false,
                        }],
                        initialValue: this.state.item.is_lgu === 1
                                        ? true
                                        : false,
                        valuePropName: "checked"
                    })(
                        <AntdSwitch 
                            checkedChildren="LGU"
                            unCheckedChildren="Not LGU"
                        />
                    )}
                </div>
                {getFieldDecorator('priority', {
                    rules: [{
                        required: false,
                    }],
                    initialValue: this.state.item.priority ?
                        this.state.item.priority : "gold"
                })(
                    <Radio.Group disabled={getFieldValue("is_lgu") === true}>
                        <Radio.Button value="gold">Gold</Radio.Button>
                        <Radio.Button value="silver">Silver</Radio.Button>
                        <Radio.Button value="bronze">Bronze</Radio.Button>
                    </Radio.Group>
                )}
           </FormItem>
            
           <AddPerks 
                formItemLayout={formItemLayout} 
                match={this.props.match} 
                state={getFieldsValue()}
                images={this.state.item.images}
                logo={this.state.logo}
                removePromo={this._removePromo}
                promos={promos}
                history={this.props.history}
            />
 

        </Form>
        {
                // <PromosList promos={this.state.form_promos} ref={(promos) => { this.promos = promos; }} />

        }
            <FormItem style={{ textAlign: 'center' }}>
                <Button onClick={this._handleSubmit} loading={this.state.loading} type="primary" htmlType="submit" className="login-form-button" style={{ width: '40%' }}>
                    Submit
                </Button>
            </FormItem>
        </div>


        return (
            <div>
                <h1>{this.state.title}</h1>
                {divForm}
            </div>
        )
    }
}

const Partner = Form.create()(Wrapper);
export default Partner;
