import React, {Component} from 'react';

import { Table, Spin, Form, Button, Modal, message } from 'antd';
import API from 'services/APIService';
import { NavLink } from 'react-router-dom';

const confirm = Modal.confirm;

var DEFAULT_STATE = {
  loading: false,
  init_loading: true,
  data: null,
  group_options: [],
  filteredInfo: null,
  sortedInfo: null,
  searchInfo: null
}

class SiteListForm extends Component {
  constructor(props) {
    super(props);
    this.state = DEFAULT_STATE;
    this.values = {};
  }

  _handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  }
  _handleDelete = (value) => {
    confirm({
      title: 'Are you sure you want to delete this?',
      content: 'This action cannot be reversed.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        this.setState({ loading: true });
        API.site_delete(value.id)
          .then((response) => {
            this._refreshList(value.subject);
          })
          .catch((error) => {
            if (error.response.data) {
              message.error(error.response.data.messages[0]);
            }
            else {
              message.error(error.message);
            }
            this.setState({ loading: false });
            console.log(error);
          })
      },
      onCancel() {
        console.log('Cancel');
      }
    });

  }

  _handleSubmit = (e) => {
    e.preventDefault();
    this.setState({loading: true});
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        this._loadData(values.search);
      }
      else {
        message.error('Error!', 5);
        this.setState({loading: false});
      }
    })
  }

  componentDidMount() {
    console.log("componentDidMount")
    this._loadData('');
  }

  _loadData = (q) => {
    API.site_list(q)
      .then((response) => {
        console.log(response.data);
        var data = response.data.data;
        this.setState({init_loading: false, loading: false, data: data})
      })
      .catch((error) => {
        if (error.response.data){
          message.error(error.response.data.messages[0]);
        }
        else {
          message.error(error.message);
        }
        this.setState({ loading: false});
        console.log(error);
      })
  }

  componentDidUpdate(prevProps) {
    console.log("componentDidUpdate")

  }

  _refreshList = () => {
    this.setState({loading: true});
    this._loadData();
  }

  render() {
    const columns = [

      {
        title: 'Name',
        key: 'name',
        render: (text, record) => (
          <span style={{fontWeight: 'bold'}}>
            {record.name}
          </span>
        )
      },
      {
        title: 'Address',
        dataIndex: 'address'
      },
      {
        title: 'Description',
        dataIndex: 'short_desc'
      },
      {
        title: 'Actions',
        key: 'action',
        render: (text, record) => {
          return <span>
            <NavLink to={'/site/' + record.id}>View</NavLink>&nbsp;&sdot;&nbsp;
                        <a onClick={this._handleDelete.bind(this, record)}>Delete</a>
            </span>

        }
      }
    ]
    var divTable;
    if (this.state.init_loading === false) {
      divTable = <Table size='small' loading={this.state.loading} pagination={{pageSize: 20}} columns={columns} dataSource={this.state.data} />
    }
    else {
      divTable = <div style={{textAlign: 'center'}}><Spin size='large' style={{margin: '150px'}} /></div>
    }

    var newSiteButton;
    newSiteButton = <Button style={{float: 'right'}} type='primary' icon='plus' href='/site/new'>New Site</Button>;

    return (
      <div>
        <h1>Sites
            {newSiteButton}
          </h1>
        {divTable}
      </div>
    )
  }
}

const SiteList = Form.create()(SiteListForm);

export default SiteList;
