import React, { Component } from 'react';
import API from 'services/APIService';
import { Card, message, Upload, Icon, Row, Col, Button } from 'antd';

var DEFAULT_STATE = {
    images: []
}

class ImageGroup extends Component {
    constructor(props) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    componentDidMount() {
        console.log("componentDidMount");
        console.log(this.props.images);
        this.setState({ images: this.props.images });
    }

    componentDidUpdate(prevProps) {
        if (this.props.images !== prevProps.images) {
            this.setState({ images: this.props.images });
        }
    }

    _deleteImage = (index) => {
        let newImages = this.state.images;
        newImages.splice(index, 1);
        this.setState({images: newImages});
    }

    _onUploadSuccess = (file_loc) => {
        let newImages = this.state.images;
        newImages.push('image?file_loc=' + file_loc);
        this.setState({ images: newImages });

    }

    render() {
        var uploader;
        var span = 8;
        if (this.state.images.length < this.props.limit) {
            uploader =
                <Col span={8} style={{ marginTop: '10px' }}>
                    <Uploader onSuccess={this._onUploadSuccess} />
                </Col>
        }
        if (this.props.limit === 1) {
            span=24;
        }
        if (this.props.span) {
            span = this.props.span;
        }
        return (
            <div>
                <Row gutter={16} type={"flex"}>
                    {
                        this.state.images.map((item, index) => {
                            return <Col key={index} span={span} style={{marginTop: '10px'}}>
                                <Card title={''} bordered={true} extra={<a onClick={this._deleteImage.bind(this, index)}>Delete</a>}>
                                    <img width="100%" src={API.get_base() + '/' + item} alt='random' />
                                </Card>
                            </Col>

                        })
                    }
                        {uploader}


                </Row>

            </div>
        )
    }
}

export default ImageGroup;


class Uploader extends Component {
    constructor(props) {
        super(props);
        this.state = { fileList: [] };
    }

    _onChange = (info) => {
        console.log(info);
        let fileList = info.fileList;

        // 1. Limit the number of uploaded files
        //    Only to show two recent uploaded files, and old ones will be replaced by the new
        fileList = fileList.slice(-1);

        // 2. read from response and show file link
        fileList = fileList.map((file) => {
            if (file.response) {
                if (file.response.error) {
                    message.error(file.response.messages[0]);
                }
                else {
                    file.file_loc = file.response.file_loc;

                }
            }

            return file;
        });
        console.log(fileList);
        this.setState({ fileList });
        if (fileList[0].file_loc) {
            this.props.onSuccess(fileList[0].file_loc);
            this.setState({ fileList: [] });
        }
    }

    render() {
        const uploadProps = {
            accept: 'image/*',
            action: API.image_upload_endpoint(),
            multiple: false,
            headers: API.token_header(),
            data: {
            },
            listType: "text",
            onChange: this._onChange,
        }
        var divForm = <Upload {...uploadProps} fileList={this.state.fileList}>
                        <Button>
                            <Icon type="upload" /> Click to upload
                        </Button>
                    </Upload>

        var divMain = divForm;
        return (
            <div>
                {divMain}
            </div>
        )
    }
}
