import React, { useState } from 'react';
import { Upload, Icon } from 'antd';
import API from 'services/APIService';
import { getImageUrl } from '../utils/image';

const EmptyLogo = (props) => {
    return (
      <div>
        <Icon type={props.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
}

const UploadFile = ({ value, onChange }) => {
    const [loading, setLoading] = useState(false);

    const _onChange = info => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            setLoading(false);
            onChange(info.file.response.file_loc);
        }
    }

    return (
        <Upload 
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            accept='image/*'
            action={API.image_upload_endpoint()}
            multiple={false}
            headers={API.token_header()}
            onChange={_onChange}
        >
            { !loading && !!value ? <img src={getImageUrl(value)} alt="avatar" style={{ width: '100%' }} /> : <EmptyLogo loading={loading} /> }
        </Upload>
    );
}

export default React.forwardRef((props, ref) => <UploadFile innerRef={ref} {...props} />);