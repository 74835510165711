import React from "react";
import { Form, Input } from "antd";
import { formItemLayout } from "../const";

export default ({ setState, getFieldDecorator, state }) => (
  <Form.Item label="Name" {...formItemLayout}>
    {getFieldDecorator("nameinput", {
      rules: [
        {
          required: true
        }
      ],
      initialValue: state.name
    })(
      <Input
        onChange={event => setState({ name: event.target.value })}
      />
    )}
  </Form.Item>
);
