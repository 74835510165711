import React, { Component } from 'react';

import { Table, Button } from 'antd';
import { Switch } from 'antd';

import API from 'services/APIService';
import { NavLink } from 'react-router-dom';
import { Form, Tag } from 'antd';
import { Modal, message } from 'antd';
import { get } from 'lodash';

const confirm = Modal.confirm;

var DEFAULT_STATE = {
    loading: false,
    meta: {},
    data: []
}

class PartnerListForm extends Component {
    constructor(props) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    componentDidMount() {
        this._loadData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.heirarchy !== this.state.heirarchy) {
            this._loadData();
        }
    }

    _handleDelete = (value) => {
        confirm({
            title: 'Are you sure you want to delete this?',
            content: 'This action cannot be reversed.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => {
                this.setState({ loading: true });
                API.partner_delete(value.id)
                    .then((response) => {
                        this._refreshList();
                    })
                    .catch((error) => {
                        if (error.response.data) {
                            message.error(error.response.data.messages[0]);
                        }
                        else {
                            message.error(error.message);
                        }
                        this.setState({ loading: false });
                    })
            },
            onCancel() {
                console.log('Cancel');
            }
        });

    }

    _loadData = () => {
        API.partner_list()
            .then((response) => {
                const { data, ...rest } = response.data;
                this.setState({ loading: false, data: data, meta: rest })
            })
            .catch((error) => {
                if (error.response.data) {
                    message.error(error.response.data.messages[0]);
                }
                else {
                    message.error(error.message);
                }
                this.setState({ loading: false });
            })
    }

    _togglePriority = (checked) => {
        const heirarchy = checked ? 'priority' : 'name';
        this.setState({ loading: true });
        API.partner_toggle_heirarchy({ heirarchy })
            .then((response) => {
                this._refreshList();
            })
            .catch((error) => {
                if (error.response.data) {
                    message.error(error.response.data.messages[0]);
                } else {
                    message.error(error.message);
                }
                this.setState({ loading: false });
            });
    }

    _refreshList = () => {
        this.setState({ loading: true });
        this._loadData();
    }

    renderTag = (record) => {
        if (!record.priority) {
            return ""
        }

        return (
            <Tag 
                color={
                    record.priority === "gold" ? "gold" :
                    record.priority === "silver" ? "#C0C0C0" :
                    "#CD7F32"
                }
                style={{
                    marginRight: "20vw"
                }}
            >
                {record.priority.charAt(0).toUpperCase() + record.priority.slice(1)}
            </Tag>
        )
    }

    columns = [
        {
            title: 'Name',
            key: 'name',
            render: (text, record) => (
                <span style={{ fontWeight: 'bold' }}>
                    {record.name}
                </span>
            )
        },
        {
            title: 'Address',
            dataIndex: 'address'
        },
        {
            title: 'Priority Tag',
            dataIndex: 'priority',
            render: (text, record) => this.renderTag(record)
        },
        {
            title: 'Actions',
            key: 'action',
            render: (text, record) => {
                return <span>
                    <NavLink to={'/partner/' + record.id}>View</NavLink>&nbsp;&sdot;&nbsp;
                    <a onClick={this._handleDelete.bind(this, record)}>Delete</a>
                </span>

            }
        }
    ]

    render() {
        return (
            <div>
                <h1>
                    Partners
                    <Button style={{ float: 'right' }} type='primary' icon='plus' href='/partner/new'>New Partner</Button>
                </h1>
                <div style={{ margin: '10px' }}>
                    <b>Heirarchy: </b>
                    <Switch 
                        checkedChildren="On" 
                        unCheckedChildren="Off" 
                        checked={get(this, 'state.meta.sort_key') === 'priority'} 
                        onChange={this._togglePriority}
                    />
                </div>
                <Table 
                    size='small' 
                    loading={this.state.loading} 
                    pagination={{ pageSize: 20 }} 
                    columns={this.columns} 
                    dataSource={this.state.data} 
                />
            </div>
        )
    }
}

const PartnerList = Form.create()(PartnerListForm);

export default PartnerList;
