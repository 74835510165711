import React, { Component } from 'react';
import { Table, Spin, Form, Button, message, Modal } from 'antd';
import API from 'services/APIService';
import { NavLink } from 'react-router-dom';
import moment from 'moment';

const confirm = Modal.confirm;

var DEFAULT_STATE = {
    loading: false,
    init_loading: true,
    data: null,
    group_options: [],
    filteredInfo: null,
    sortedInfo: null,
    searchInfo: null
}

class EventListForm extends Component {
    constructor(props) {
        super(props);
        this.state = DEFAULT_STATE;
        this.values = {};
    }

    _handleChange = (pagination, filters, sorter) => {
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
        });
    }
    _handleDelete = (value) => {
        confirm({
            title: 'Are you sure you want to delete this?',
            content: 'This action cannot be reversed.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => {
                this.setState({ loading: true });
                API.event_delete(value.id)
                    .then((response) => {
                        this._refreshList(value.subject);
                    })
                    .catch((error) => {
                        if (error.response.data) {
                            message.error(error.response.data.messages[0]);
                        }
                        else {
                            message.error(error.message);
                        }
                        this.setState({ loading: false });
                    })
            },
            onCancel() {
                
            }
        });
    }

    _handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this._loadData(values.search);
            }
            else {
                message.error('Error!', 5);
                this.setState({ loading: false });
            }
        })
    }

    componentDidMount() {
        this._loadData('');
    }

    _loadData = (q) => {
        API.event_list(q)
            .then((response) => {
                var data = response.data.data;
                this.setState({ init_loading: false, loading: false, data: data })
            })
            .catch((error) => {
                if (error.response.data) {
                    message.error(error.response.data.messages[0]);
                }
                else {
                    message.error(error.message);
                }
                this.setState({ loading: false });
            })
    }

    componentDidUpdate(prevProps) {
       
    }

    _refreshList = () => {
        this.setState({ loading: true });
        this._loadData();
    }

    render() {
        const columns = [
            {
                title: 'Name',
                key: 'name',
                render: (text, record) => (
                    <span style={{ fontWeight: 'bold' }}>
                        {record.name}
                    </span>
                )
            },
            {
                title: 'Start',
                dataIndex: 'start_date',
                render: value => !value ? '' : moment(value, 'YYYY-MM-DD').format('MMMM DD, YYYY')
            },
            {
                title: 'End',
                dataIndex: 'end_date',
                render: value => !value ? '' : moment(value, 'YYYY-MM-DD').format('MMMM DD, YYYY')
            },
            {
                title: 'Description',
                dataIndex: 'full_description',
                onCell: () => {
                    return {
                       style: {
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '200px',
                       }
                    }
                },
            },
            {
                title: 'Actions',
                key: 'action',
                render: (text, record) => {
                    return <span>
                        <NavLink to={'/event/' + record.id}>View</NavLink>&nbsp;&sdot;&nbsp;
                        <a onClick={this._handleDelete.bind(this, record)}>Delete</a>
                    </span>

                }
            }
        ]
        var divTable;
        if (this.state.init_loading === false) {
            divTable = <Table size='small' loading={this.state.loading} pagination={{ pageSize: 20 }} columns={columns} dataSource={this.state.data} />
        }
        else {
            divTable = <div style={{ textAlign: 'center' }}><Spin size='large' style={{ margin: '150px' }} /></div>
        }

        var newEventButton;
        newEventButton = <Button style={{ float: 'right' }} type='primary' icon='plus' href='/event/new'>New Event</Button>;

        return (
            <div>
                <h1>Events
            {newEventButton}
                </h1>
                {divTable}
            </div>
        )
    }
}

const EventList = Form.create()(EventListForm);

export default EventList;
