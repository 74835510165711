import React, {Component} from 'react';
import { Form, Icon, Input, Button, message, Layout } from 'antd';
import AuthService from 'services/AuthService';
import API from 'services/APIService';

const FormItem = Form.Item;
const { Content } = Layout;

var DEFAULT_STATE = {
  loading: false
}

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = DEFAULT_STATE;
  }

  componentDidMount() {
    console.log("componentDidMount");
  }

  componentDidUpdate(prevProps) {
    console.log("componentDidUpdate")
  }

  _handleSubmit = (e) => {
    e.preventDefault();
    this.setState({loading: true});
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        API.login(values.email, values.password)
        .then((response) => {
          AuthService.setToken(response.data.token);
          AuthService.set(response.data.user);
          message.info('Login successful', 1);
            this.props.history.push('/users');
        })
        .catch((error) => {
          this.setState({loading: false});
          console.log(error);
          message.error(error.response.data.messages[0], 5);
        })
      }
      else {
        message.error('Login Error!', 5);
        this.setState({loading: false});
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Content style={{ padding: '50px 50px 50px 50px', marginTop: 0 , height: '70vh'}} className="login-container">
        <div style={{ background: '#fff', padding: 24, minHeight: 280, minWidth: 400 }}>
      <Form onSubmit={this._handleSubmit} className="login-form" style={{ width: '350px' }}>
        <h1>Sign In</h1>
        <FormItem>
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Please input your email!' }],
          })(
            <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email address" />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }],
          })(
            <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
          )}
        </FormItem>
        <FormItem style={{ textAlign: 'left' }}>

          <div></div>
          <Button loading={this.state.loading} type="primary" htmlType="submit" className="login-form-button" style={{ width: '100%' }} >
            Log in
          </Button>
          <div></div>

        </FormItem>
      </Form>
      </div>
      </Content>
    )
  }
}
const ViewLogin = Form.create()(LoginForm);

export default ViewLogin;
