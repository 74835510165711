import React, { useState, useEffect, useMemo } from 'react';
import { Button, Table } from 'antd';
import ConditionModal from './ConditionModal';
import uniqueId from 'lodash/uniqueId';

export const USER_ACTIONS = [
    { label: 'LOGIN', value: 'ua_login' },
    { label: 'REGISTER', value: 'ua_register' },
    { label: 'UPDATE_PROFILE', value: 'ua_update_profile' },
    { label: 'LINK FACEBOOK', value: 'ua_link_facebook' },
    { label: 'JOIN EVENT' , value: 'ua_join_event'},
    { label: 'SUBMIT TRASH COLLECTION' , value: 'ua_submit_trash_collection' },
    { label: 'VIEW ALL SCREENS', value: 'ua_view_all_screens' },
];

function getConditionView(condition) {
    return `${condition.check}: ${condition.condition}`;
}

const BadgeCondition = ({ value = [], onChange }) => {
    const COLUMNS = [
        {
            title: 'Action',
            dataIndex: 'action',
            render: (value) => (
                <span style={{ fontWeight: 'bold' }}>
                    {USER_ACTIONS.find(action => action.value === value).label}
                </span>
            )
        },
        {
            title: 'Property To Check',
            dataIndex: 'condition.property',
            render: (value) => value || 'NA'
        },
        {
            title: 'Condtion',
            dataIndex: 'condition',
            render: (value) => getConditionView(value)
        },
        {
            title: (
                <span>
                    Actions&nbsp;&sdot;&nbsp;
                    <Button type='primary' icon='plus' onClick={() => setSelected('new')}/>
                </span>
            ),
            key: 'options',
            render: (_, record, index) => {
                return (
                    <span>
                        <a onClick={() => setSelected(record)}>Edit</a>&nbsp;&sdot;&nbsp;
                        <a onClick={() => _handleDelete(index)}>Delete</a>
                    </span>
                )
            }
        }
    ];

    const [selected, setSelected] = useState();

    const tableValues = useMemo(() => {
        return value.map(val => { 
            val.key = uniqueId();
            return val;
        })
    }, [value]);

    const _handleDelete = (index) => {
        const newValues = [...value];
        onChange(newValues.filter((_, indx) => index !== indx));
    }

    const _handleSave = (saved) => {
        if (selected === 'new') {
            onChange([...value, saved].map(({ key, ...item }) => item));
        } else {
            onChange(value.map(({ key, ...item }) => {
                if (selected.key === key) {
                    return item;
                } else {
                    return item;
                }
            }))
        }
        setSelected(undefined);
    }

    return (
        <React.Fragment>
            <Table 
                dataSource={tableValues} 
                columns={COLUMNS}
            />
            <ConditionModal
                selected={selected}
                onCancel={() => setSelected(undefined)}
                onSave={_handleSave}
            />
        </React.Fragment>
    );
}

export default React.forwardRef((props, ref) => <BadgeCondition innerRef={ref} {...props} />);