import React from 'react';
import { Select, Spin, Button, Icon } from 'antd';
import useCleanupType from '../hooks/useCleanupType';

const Option = Select.Option;

export default ({
  value,
  onChange,
}) => {
  const [fetching, response = { cleanup_types: [] }, updateParams] = useCleanupType({});

  const handleChange = (value) => {
    if (onChange) {
      onChange(value);
    }
  }

  return (
    <span>
      <Select
        showSearch
        value={value}
        placeholder={'Cleanup Type'}
        notFoundContent={fetching ? <Spin size="small" /> : 'Not Found'}
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={handleChange}
        style={{ width: '300px' }}
      >
        <Option key={'all'} value={'all'}>All</Option>
        { response.cleanup_types.map(d => <Option key={d.type_of_cleanup} value={d.type_of_cleanup}>{d.type_of_cleanup.toUpperCase()}</Option>) }
      </Select>
      <Button onClick={() => updateParams(params => ++params)} style={{ marginLeft: '5px' }}>
        <Icon type="sync" spin={fetching} />
      </Button>
    </span>
  );
}