import API from 'services/APIService';

export const getImageUrl = (url) => {
    if (url.startsWith('image')) {
        return `${API.get_base()}/${url}`;
    }
    if (url.startsWith('/image')) {
        return `${API.get_base()}${url}`;
    }
    return `${API.get_base()}/image?file_loc=${url}`;
}