import React, {Component} from 'react';
import API from 'services/APIService';
import { Table, Modal, message } from 'antd';

const confirm = Modal.confirm;

var DEFAULT_STATE = {
  subjects: []
}

class MessageList extends Component {
  constructor(props) {
    super(props);
    this.state = DEFAULT_STATE;
  }


  _refreshMessages = () => {
    API.message_list()
      .then((response) =>{
        console.log(response.data);
        var data = response.data.data;
        this.setState({ loading: false, comments: data})
      })
      .catch((error) => {
        if (error.response.data){
          message.error(error.response.data.messages[0]);
        }
        else {
          message.error(error.message);
        }
        this.setState({ loading: false});
        console.log(error);
      })
  }

  _handleDelete = (value) => {
    confirm({
      title: 'Are you sure you want to delete this?',
      content: 'This action cannot be reversed.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        this.setState({loading: true});
        API.message_delete(value.id)
          .then((response) => {
            this._refreshMessages();
          })
          .catch((error) => {
            if (error.response.data){
              message.error(error.response.data.messages[0]);
            }
            else {
              message.error(error.message);
            }
            this.setState({ loading: false});
            console.log(error);
          })
      },
      onCancel() {
        console.log('Cancel');
      }
    });

  }

  componentDidMount() {
    console.log("componentDidMount")
    this._refreshMessages();
  }

  componentDidUpdate(prevProps) {
    console.log("componentDidUpdate")
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState(DEFAULT_STATE)

    }
  }

  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name'
      },
      {
        title: 'Email',
        dataIndex: 'email'
      },
      {
        title: 'Contact Number',
        dataIndex: 'contact_number'
      },
      {
        title: 'Date',
        dataIndex: 'created_at'
      },
      {
        title: 'Message',
        dataIndex: 'message'
      },
      {
        title: 'Actions',
        key: 'action',
        render: (text, record) => {
          return <span><a onClick={this._handleDelete.bind(this, record)}>Delete</a></span>

        }
      }
    ]
    var commentsDiv;
    if (this.state.comments) {
      commentsDiv = <Table size='small' loading={this.state.loading} pagination={{pageSize: 20}} columns={columns} dataSource={this.state.comments} />
    }
    return (
      <div>
        <h1>Messages</h1>
        {commentsDiv}
      </div>
    )
  }
}

export default MessageList;
