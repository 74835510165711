import React, { useState } from 'react';
import { Button } from 'antd';
import {
  CleanupTypeSelect,
  DataDisplaySelect,
  Parent,
  Header,
  HeaderTitle,
  HeaderSubtitle,
  ListHeader,
  ListHeaderTitle
} from './components';
import ByGroup from './containers/ByGroup';
import ByType from './containers/ByType';
import LocalConcernAndAnimals from './containers/LocalConcernAndAnimals';

const TrashCollections = ({ match, history }) => {
  const eventId = match.params.id;
  const [cleanUpType, setCleanupType] = useState('all');
  const [dataDisplay, setDataDisplay] = useState('group');
  return (
    <Parent>
      <Header>
        <HeaderTitle>
          <Button
            onClick={() => history.goBack()}
            icon='arrow-left'
            type='ghost'
            style={{ marginRight: 'auto', border: 'none' }}
          />
          Trash Collection Results
          <Button
            icon='arrow-right'
            type='ghost'
            style={{ marginLeft: 'auto', border: 'none', visibility: 'hidden' }}
          />
        </HeaderTitle>
        <HeaderSubtitle></HeaderSubtitle>
      </Header>
      <ListHeader>
        <ListHeaderTitle>Choose Type of Cleanup: </ListHeaderTitle>
        <CleanupTypeSelect 
          value={cleanUpType}
          onChange={cleanup_type => setCleanupType(cleanup_type)}
        />
      </ListHeader>
      <ListHeader>
        <ListHeaderTitle>Show Data: </ListHeaderTitle>
        <DataDisplaySelect 
          value={dataDisplay}
          onChange={dataDisplay => setDataDisplay(dataDisplay)}
        />
      </ListHeader>
      {
        dataDisplay === 'group' && (
          <ByGroup
            eventId={eventId}
            cleanUpType={cleanUpType}
          />
        )
      }
      {
        dataDisplay === 'type' && (
          <ByType
            eventId={eventId}
            cleanUpType={cleanUpType}
          />
        )
      }
      <LocalConcernAndAnimals
        eventId={eventId}
        cleanUpType={cleanUpType}
      />
    </Parent>
  );
}

export default TrashCollections;