import React, { Component } from "react";
import { Card, Row, Col, Button } from "antd";
import API from "../../../../../services/APIService";

export default class extends Component {
  componentDidMount() {
    console.log(this.props.location);
  }

  onDelete = index => {
    const promos = this.props.promos ? this.props.promos : [];
    const state = this.props.state ? this.props.state : {};
    const newPromos = [...promos.slice(0, index), ...promos.slice(index + 1)];

    const newLocation = {
      state: { ...state, promos: newPromos }
    };

    this.props.history.replace(newLocation);
  };

  onEdit = index => {
    const promos = this.props.promos ? this.props.promos : [];
    const state = this.props.state ? this.props.state : {};
    const images = this.props.images;
    const logo = this.props.logo;

    const newLocation = {
      pathname: `/partner/${
        this.props.match.params.id ? this.props.match.params.id : "new"
      }/promo/${index}`,
      state: { ...state, promos, images, logo }
    };

    this.props.history.replace(newLocation);
  };

  renderCardHeader = data => (
    <Row>
      <Col>
        <h2>{data.name}</h2>
      </Col>
    </Row>
  );

  renderCardSubHeader = (data, index) => (
    <Row>
      <Col>
        {data.for_passport === 1 ? "For member with passport" : ""}

        <div style={{ float: "right" }}>
          <Button icon="delete" onClick={() => this.onDelete(index)} />
          <Button icon="edit" onClick={() => this.onEdit(index)} />
        </div>
      </Col>
    </Row>
  );

  renderCard = (data, index) => (
    <Col span={5} key={index} style={{ marginBottom: "10px" }}>
      <Card
        hoverable
        cover={<img src={`${API.get_base()}/image?file_loc=${data.image}`} alt='promo' />}
        style={{ height: "100%" }}
      >
        <div>
          {this.renderCardHeader(data)}
          {this.renderCardSubHeader(data, index)}
        </div>
      </Card>
    </Col>
  );

  render() {
    return (
      <React.Fragment>
        <h3>List of Promos</h3>
        <Row gutter={16} type={"flex"}>
          {this.props.promos.map((promo, index) =>
            this.renderCard(promo, index)
          )}
        </Row>
      </React.Fragment>
    );
  }
}
