import React, { useEffect } from 'react';
import { Modal, Form } from 'antd';
import useApproveMembership from '../hooks/useApproveMembership';
import get from 'lodash/get';

const ApproveMembershipModal = ({
    user,
    visible,
    onCancelPress,
    onApproveSuccess
}) => {
    const [isPosting, rePost, isSuccessful, responseData] = useApproveMembership();

    useEffect(() => {
        if (!isPosting && isSuccessful) {
            onApproveSuccess(responseData);
        }
    }, [isPosting, isSuccessful]);

    return (
        <Modal
            visible={visible}
            title="Approve User"
            okText="Approve"
            onCancel={onCancelPress}
            onOk={() => rePost(user.id)}
            loading={isPosting}
        >
          <h1>{get(user, 'name', '')} is applying for passport membership.</h1>
          <Form.Item
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 12 }}
              style={{ margin: '0px', padding: '0px' }}
              label={"Member's ID"}
              colon
          >
            { get(user, 'members_id', '') }
          </Form.Item>
        </Modal>
    );
}

export default ApproveMembershipModal;