import { useState, useEffect, useRef } from "react";
import API from 'services/APIService';
import { message } from "antd";

export default ({
  params = {
    page: 1,
    filters: {
        type_of_cleanup: undefined
    },
  },
  runOnInitiate = true
}) => {
  const isFirstRun = useRef(true);
  // used for refetching
  const [queryParams, updateQueryParams] = useState(params);
  // useful for loading icons
  const [isFetching, updateIsFetching] = useState(false);
  /**
   * the actual data, default is undefined,
   * so a checker is needed when using the
   * returned data to evaluate it's existence
   */
  const [fetchedData, updateFetchedData] = useState(undefined);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      if (!runOnInitiate) {
        return;
      }
    }

    updateIsFetching(true);
    if (!queryParams.event_id) {
        return;
    }


    API.event_collectors_totals_group(queryParams)
      .then(response => updateFetchedData(response.data))
      .catch(error => {
        if (error.response.data) { 
            message.error(error.response.data.messages[0]); 
        } else { 
            message.error(error.message); 
        }
      })
      .finally(() => updateIsFetching(false));
  }, [queryParams]);

  /**
   * returning an array so that
   * end-user can use whatever
   * name for the values
   *
   * e.g.
   * const [loading, data] = useFetchData();
   */
  return [isFetching, fetchedData, updateQueryParams];
};
