import React, { useState, useEffect } from 'react';
import { Modal, Form, Select, InputNumber, DatePicker, Button, message, Icon } from 'antd';
import moment from 'moment';
import API from 'services/APIService';

import { USER_ACTIONS } from './BadgeCondition';

const FORM_ITEM_LAYOUT = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

function getAvailableProperties(action) {
    switch (action) {
        case 'ua_update_profile':
            return ['is_diver'];
        default:
            return [];
    }
}

function getAvailablePropertyChecks(action, property) {
    switch (action) {
        case 'ua_update_profile':
            if (property === 'is_diver') {
                return ['isTrue'];
            } else {
                return ['isCompleted'];
            }
        case 'ua_login':
            return ['count'];
        case 'ua_register':
            return ['onOrBefore'];
        case 'ua_join_event':
        case 'ua_submit_trash_collection':
            return ['event', 'isTrue', 'count'];
        case 'ua_link_facebook':
            return ['isTrue'];
        case 'ua_view_all_screens':
            return ['isTrue'];
        default:
            return [];
    }
}

const ConditionValue = ({ check, value, onChange }) => {
    switch (check) {
        case 'isTrue':
        case 'isCompleted':
            return (
                <Select value={value} onChange={onChange} style={{ width: '100%' }}>
                    <Select.Option value={true}>True</Select.Option>
                    <Select.Option value={false}>False</Select.Option>
                </Select>
            );
        case 'count':
            return (
                <InputNumber step={1} value={typeof value === 'number' ? value : 0} onChange={onChange} style={{ width: '100%' }} />
            );
        case 'onOrBefore': {
            const dateValue = moment(value, 'YYYY-MM-DD', true);
            return (
                <DatePicker
                    value={dateValue.isValid() ? dateValue : undefined}
                    onChange={(_, dateString) => onChange(dateString)}
                    format={'YYYY-MM-DD'}
                    style={{ width: '100%' }}
                />
            )
        }
        case 'event': {
            const [events, setEvents] = useState([]);
            const [loading, setLoading] = useState(false);
            const load = () => {
                setLoading(true);
                API.event_list()
                .then((response) => {
                    setEvents(response.data.data);
                    setLoading(false);
                })
                .catch((error) => {
                    if (error.response.data) {
                        message.error(error.response.data.messages[0]);
                    } else {
                        message.error(error.message);
                    }
                    setLoading(false);
                });
            }
            useEffect(() => { load() }, []);
            return (
                <span>
                    <Select 
                        value={value} 
                        onChange={onChange} 
                        loading={loading} 
                        style={{ width: '80%' }}
                    >
                        {
                            events.map(event => (
                                <Select.Option
                                    key={event.id.toString()}
                                    value={event.id.toString()}
                                >
                                    { event.name }
                                </Select.Option>
                            ))
                        }
                    </Select>
                    &nbsp;&sdot;&nbsp;
                    <Button
                        onClick={load}
                    >
                        <Icon 
                            type='sync'
                            spin={loading}
                        />
                    </Button>
                </span>
            );
        }
        default: 
            return null;
    }
}
const ConditionValueWithRef = React.forwardRef((props, ref) => <ConditionValue innerRef={ref} {...props} />);

const ConditionModal = ({
    selected,
    onCancel,
    onSave,
    form
}) => {
    const _handleSave = () => {
        form.validateFields((err, values) => {
            if (!err) {
                onSave(values);
            }
        });
    }

    const _handleActionChange = () => {
        form.setFieldsValue({
            condition: {
                property: undefined,
                check: undefined,
                condition: undefined
            }
        });
    }

    if (!selected) {
        return null;
    }
    
    const action = form.getFieldValue('action');
    const property = form.getFieldValue('condition.property');
    const check = form.getFieldValue('condition.check');
    const availableProperties = getAvailableProperties(action);
    const availablePropertyChecks = getAvailablePropertyChecks(action, property);

    useEffect(() => {
        if (!selected || selected === 'new') {
            return;
        }
        form.setFieldsValue({
            action: selected.action,
            condition: {
                property: selected.condition.property,
                check: selected.condition.check,
                condition: selected.condition.condition
            }
        });
    }, [selected]);

    return (
        <Modal
            title='Condition'
            visible
            onOk={_handleSave}
            onCancel={onCancel}
            footer={[
                <Button key="back" type="danger" onClick={onCancel}>
                  Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={_handleSave}>
                  Submit
                </Button>,
            ]}
        >
            <Form.Item
                { ...FORM_ITEM_LAYOUT }
                label='Action'
            >
                {
                    form.getFieldDecorator('action', {
                        required: true,
                        message: 'Please Input!',
                        initialValue: selected !== 'new' ? selected.action : ''
                    })(
                        <Select 
                            style={{ width: '100%' }}
                            onChange={_handleActionChange}
                        >
                            { 
                                USER_ACTIONS.map(option => (
                                    <Select.Option 
                                        key={option.value} 
                                        value={option.value}
                                    >
                                        {option.label}
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    )
                }
            </Form.Item>
            <Form.Item
                { ...FORM_ITEM_LAYOUT }
                label='Propety to check'
            >
                {
                    form.getFieldDecorator('condition.property', {
                        required: availableProperties.length > 0,
                        message: 'Please Input!',
                        initialValue: selected !== 'new' ? selected.condition.property : undefined
                    })(
                        <Select style={{ width: '100%' }}>
                            { 
                                availableProperties.map(option => (
                                    <Select.Option 
                                        key={option} 
                                        value={option}
                                    >
                                        {option}
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    )
                }
            </Form.Item>
            <Form.Item
                { ...FORM_ITEM_LAYOUT }
                label='What to check'
            >
                {
                    form.getFieldDecorator('condition.check', {
                        required: true,
                        message: 'Please Input!',
                        initialValue: selected !== 'new' ? selected.condition.check : undefined
                    })(
                        <Select style={{ width: '100%' }}>
                            { 
                                availablePropertyChecks.map(option => (
                                    <Select.Option 
                                        key={option} 
                                        value={option}
                                    >
                                        {option}
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    )
                }
            </Form.Item>
            <Form.Item
                { ...FORM_ITEM_LAYOUT }
                label='Required value'
            >
                {
                    form.getFieldDecorator('condition.condition', {
                        required: true,
                        message: 'Please Input!',
                        initialValue: selected !== 'new' ? selected.condition.condition : undefined
                    })(
                        <ConditionValueWithRef 
                            check={check}
                        />
                    )
                }
            </Form.Item>
        </Modal>
    );
}

const ConditionalModalWithRef = React.forwardRef((props, ref) => <ConditionModal innerRef={ref} {...props} />);
export default Form.create()(ConditionalModalWithRef);