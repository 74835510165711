import React from "react";
import { Form, Input } from "antd";
import { formItemLayout } from "../const";

export default ({ setState, getFieldDecorator, state }) => (
  <Form.Item label="Mechanics" {...formItemLayout}>
    {getFieldDecorator("mechanics", {
      rules: [
        {
          required: true
        }
      ],
      initialValue: state.mechanics
    })(
      <Input.TextArea
        onChange={event => setState({ mechanics: event.target.value })}
        rows={5}
      />
    )}
  </Form.Item>
);
