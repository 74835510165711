import React from 'react';
import { Select, Spin, Button, Icon } from 'antd';
import useSites from '../hooks/useSites';

const Option = Select.Option;

export default React.forwardRef(({
  value,
  onChange,
}, _) => {
  const [fetching, sites = { data: [] }, updateParams] = useSites({
    params: 1
  });

  const handleChange = (value) => {
    if (onChange) {
      onChange(parseInt(value));
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      <Select
        showSearch
        value={!value ? '' : value.toString()}
        placeholder={'Sites'}
        notFoundContent={fetching ? <Spin size="small" /> : 'Not Found'}
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={handleChange}
        style={{ flexGrow: 1 }}
      >
        {
          sites.data.map((item) => <Option value={item.id.toString()} key={item.id.toString()}>{item.name}</Option>)
        }
      </Select>
      <Button onClick={() => updateParams(params => !params ? 1 : ++params)}>
        <Icon type="sync" spin={fetching} />
      </Button>
    </div>
  );
})