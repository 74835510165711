import React from "react";
import { Form, Radio } from "antd";
import { formItemLayout } from "../const";

export default ({ setState, state, getFieldDecorator }) => (
  <Form.Item label="Promo" {...formItemLayout}>
    {getFieldDecorator("promo", {
      rules: [
        {
          required: true
        }
      ],
      initialValue: state.for_passport ? state.for_passport : false
    })(
      <Radio.Group
        onChange={event => setState({ for_passport: event.target.value })}
      >
        <Radio.Button value={1}>With Passport</Radio.Button>
        <Radio.Button value={0}>Without Passport</Radio.Button>
      </Radio.Group>
    )}
  </Form.Item>
);
