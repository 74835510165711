import React, { useReducer, useEffect } from 'react';
import { Table, Avatar, Button } from 'antd';
import API from 'services/APIService';
import { NavLink } from 'react-router-dom';
import { Modal, message } from 'antd';

const DEFAULT_STATE = {
    loading: false,
    data: [],
    table: {
        page: 1,
        total: 0
    }
}

const ACTION_DELETE = 'ACTION_DELETE';
const ACTION_LOAD = 'ACTION_LOAD';
const ACTION_LOAD_SUCCESS = 'ACTION_LOAD_SUCCESS';

const reducer = (state, action) => {
    switch (action.type) {
        case ACTION_DELETE: {
            return {
                ...state,
                loading: action.payload.loading
            };
        }
        case ACTION_LOAD: {
            return {
                ...state,
                loading: action.payload.loading,
            };
        }
        case ACTION_LOAD_SUCCESS: {
            return {
                ...state,
                loading: action.payload.loading,
                data: action.payload.data,
                table: {
                    page: action.payload.current_page,
                    total: action.payload.total
                }
            };
        }
        default:
            return state;
    }
}

function BadgeList() {
    const [state, dispatch] = useReducer(reducer, DEFAULT_STATE);
    
    const _handleDelete = (value) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this?',
            content: 'This action cannot be reversed.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => {
                dispatch({ type: ACTION_DELETE, payload: { loading: true }});
                API.badge_delete(value.id)
                    .then((response) => {
                        this._refreshList(value.subject);
                    })
                    .catch((error) => {
                        if (error.response.data) {
                            message.error(error.response.data.messages[0]);
                        } else {
                            message.error(error.message);
                        }
                        dispatch({ type: ACTION_DELETE, payload: { loading: false }});
                    });
            },
            onCancel() { }
        });
    }

    const _loadData = (page) => {
        dispatch({ type: ACTION_LOAD, payload: { loading: true }});
        API.badge_list(page)
            .then((response) => {
                dispatch({ 
                    type: ACTION_LOAD_SUCCESS, 
                    payload: { 
                        loading: false, 
                        data: response.data.data, 
                        table: {
                            current_page: response.data.current_page,
                            total: response.data.total
                        } 
                    }
                });
            })
            .catch((error) => {
                if (error.response.data) {
                    message.error(error.response.data.messages[0]);
                } else {
                    message.error(error.message);
                }
                dispatch({ type: ACTION_LOAD, payload: { loading: false }});
            });
    }

    const COLUMNS = [
        {
            title: 'Icon',
            dataIndex: 'icon',
            width: 100,
            render: (value) => (
                <Avatar
                    size={64}
                    src={API.get_base() + '/image?file_loc=' + value}
                />
            )
        },
        {
            title: 'Name',
            dataIndex: 'name',
            render: (value) => (
                <span style={{ fontWeight: 'bold' }}>
                    {value}
                </span>
            )
        },
        {
            title: 'Description',
            dataIndex: 'description'
        },
        {
            title: 'Actions',
            key: 'action',
            width: 150,
            render: (_, record) => {
                return (
                    <span>
                        <NavLink to={'/badge/' + record.id}>View</NavLink>&nbsp;&sdot;&nbsp;
                        <a onClick={() => _handleDelete(record)}>Delete</a>
                    </span>
                )
            }
        }
    ]

    useEffect(() => {
        _loadData(1);
    }, []);

    return (
        <div>
            <h1>
                Badges 
                <Button 
                    style={{ float: 'right' }} 
                    type='primary' 
                    icon='plus' 
                    href='/badge/new'
                >
                    New Badge
                </Button>
            </h1>
            <Table 
                size='small' 
                loading={state.loading} 
                pagination={{ 
                    current: state.table.page,
                    pageSize: 20,
                    total: state.table.total
                }} 
                columns={COLUMNS} 
                dataSource={state.data} 
                onChange={_loadData}
                rowKey='id'
            />
        </div>
    );
}

export default BadgeList;
