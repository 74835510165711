import React, { useEffect } from "react";
import { AutoComplete } from "antd";
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";

const GooglePlacesComponent = ({ value, onChange, onSelected }) => {
    const {
        ready,
        value: placesValue,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        debounce: 300,
    });

    const _handleSelect = async (address) => {
        onChange(address);
        clearSuggestions();

        const results = await getGeocode({ address });
        const { lat, lng } = getLatLng(results[0]);
        onSelected({ lat, lng });
    }

    useEffect(() => {
        setValue(value);
    }, [value]);

    return (
        <AutoComplete 
            disabled={!ready}
            value={placesValue}
            onSearch={(search) => {
                onChange(search);
            }}
            onSelect={_handleSelect}
        >
            {
                status === "OK" && (
                    data.map(place => <AutoComplete.Option key={place.reference} value={place.description} option={place}>{place.description}</AutoComplete.Option>)
                )
            }
        </AutoComplete>
    );
};

const GooglePlacesComponentWithRef = React.forwardRef((props, ref) => <GooglePlacesComponent innerRef={ref} {...props} />);
export default GooglePlacesComponentWithRef;