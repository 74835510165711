import React from 'react';
import { Form, Spin } from 'antd';
import { useJsApiLoader } from '@react-google-maps/api';
import GooglePlacesComponent from '../../../../../components/GooglePlacesComponent';
import GoogleMapComponent from '../../../../../components/GoogleMapComponent';

const FormItem = Form.Item;

const GoogleMapPlaces = ({
    address, 
    form,
    formItemLayout,
    selected,
    setSelected
}) => {
    const { getFieldDecorator } = form;
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyBe5Gla-mF_16S_tKkBfyxg59irZFDnSNY',
        libraries: ['places'],
    });

    if (!isLoaded) {
        return (
            <div><Spin /></div>
        );
    }

    return (
        <React.Fragment>
            <FormItem {...formItemLayout} label="Address" >
                {getFieldDecorator('address', {
                    rules: [{ required: true, message: 'Please input!' }],
                    initialValue: address || ''
                })(
                    <GooglePlacesComponent 
                        onSelected={setSelected}
                    />
                )}
            </FormItem>
            <FormItem {...formItemLayout} label="Map" required>
                <GoogleMapComponent 
                    marker={selected}
                    onClick={(coord) => setSelected({ lat: coord.latLng.lat(), lng: coord.latLng.lng() })}
                />
            </FormItem>
        </React.Fragment>
    )
}

export default GoogleMapPlaces;