import { useState, useEffect, useRef } from "react";
import API from 'services/APIService';
import { message } from "antd";
import get from 'lodash/get';

export default ({
  params,
  runOnInitiate = true
}) => {
  const isFirstRun = useRef(true);
  // used for refetching
  const [queryParams, updateQueryParams] = useState(params);
  // useful for loading icons
  const [isFetching, updateIsFetching] = useState(false);
  /**
   * the actual data, default is undefined,
   * so a checker is needed when using the
   * returned data to evaluate it's existence
   */
  const [fetchedData, updateFetchedData] = useState(undefined);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      if (!runOnInitiate) {
        return;
      }
    }

    updateIsFetching(true);
    API.site_list()
      .then(response => updateFetchedData(response.data))
      .catch(error => {
        if (get(error, 'response.data')) { 
            message.error(get(error, 'response.data.messages.0')); 
        } else { 
            message.error(error.message); 
        }
      })
      .finally(() => updateIsFetching(false));
  }, [queryParams]);

  /**
   * returning an array so that
   * end-user can use whatever
   * name for the values
   *
   * e.g.
   * const [loading, data] = useFetchData();
   */
  return [isFetching, fetchedData, updateQueryParams];
};
