import AuthService from './AuthService';
var axios = require('axios');

var BASE_URL = 
  'https://api.shoreitup.org/api';
  // 'http://192.168.1.9:8000/api'; 
  // 'https://siu-api.staging2.acctechnology.ph/api'; 
  // 'http://shore-it-up-backend.test/api'

var API = {
  get_base: function() {
    return BASE_URL;
  },
  get_upload_endpoint: function() {
    return BASE_URL + '/upload';
  },
  token_header: function() {
    return {
      Authorization: "Bearer " + AuthService.getToken()
    };
  },
  user_id: function() {
    return AuthService.get().id;
  },

  site_list: function() {
    return axios.get(BASE_URL + '/sites', {headers: this.token_header()})
  },
  site_create: function(data) {
    return axios.post(BASE_URL + '/site', data, {headers: this.token_header()})
  },
  site_read: function(id) {
    return axios.get(BASE_URL + '/site/' + id, {headers: this.token_header()})
  },
  site_update: function(id, payload) {
    return axios.put(BASE_URL + '/site/' + id, payload, {headers: this.token_header()})
  },
  site_delete: function(id) {
    return axios.delete(BASE_URL + '/site/' + id, {headers: this.token_header()})
  },

  project_list: function () {
    return axios.get(BASE_URL + '/projects', { headers: this.token_header() })
  },
  project_create: function (data) {
    return axios.post(BASE_URL + '/project', data, { headers: this.token_header() })
  },
  project_read: function (id) {
    return axios.get(BASE_URL + '/project/' + id, { headers: this.token_header() })
  },
  project_update: function (id, payload) {
    return axios.put(BASE_URL + '/project/' + id, payload, { headers: this.token_header() })
  },
  project_delete: function (id) {
    return axios.delete(BASE_URL + '/project/' + id, { headers: this.token_header() })
  },

  event_list: function () {
    return axios.get(BASE_URL + '/events', { headers: this.token_header() })
  },
  event_create: function (data) {
    return axios.post(BASE_URL + '/event', data, { headers: this.token_header() })
  },
  event_read: function (id) {
    return axios.get(BASE_URL + '/event/' + id, { headers: this.token_header() })
  },
  event_update: function (id, payload) {
    return axios.put(BASE_URL + '/event/' + id, payload, { headers: this.token_header() })
  },
  event_delete: function (id) {
    return axios.delete(BASE_URL + '/event/' + id, { headers: this.token_header() })
  },
  event_registrations: function (id) {
    return axios.get(BASE_URL + '/event/' + id + '/registrations', { headers: this.token_header() })
  },
  partner_toggle_heirarchy: function (data) {
    return axios.put(BASE_URL + '/partner/heirarchy', data, { headers: this.token_header() })
  },
  partner_list: function (params) {
    return axios.get(BASE_URL + '/partners', { headers: this.token_header(), params })
  },
  partner_create: function (data) {
    return axios.post(BASE_URL + '/partner', data, { headers: this.token_header() })
  },
  partner_read: function (id) {
    return axios.get(BASE_URL + '/partner/' + id, { headers: this.token_header() })
  },
  partner_update: function (id, payload) {
    return axios.put(BASE_URL + '/partner/' + id, payload, { headers: this.token_header() })
  },
  partner_delete: function (id) {
    return axios.delete(BASE_URL + '/partner/' + id, { headers: this.token_header() })
  },

  product_list: function () {
    return axios.get(BASE_URL + '/products', { headers: this.token_header() })
  },
  product_create: function (data) {
    return axios.post(BASE_URL + '/product', data, { headers: this.token_header() })
  },
  product_read: function (id) {
    return axios.get(BASE_URL + '/product/' + id, { headers: this.token_header() })
  },
  product_update: function (id, payload) {
    return axios.put(BASE_URL + '/product/' + id, payload, { headers: this.token_header() })
  },
  product_delete: function (id) {
    return axios.delete(BASE_URL + '/product/' + id, { headers: this.token_header() })
  },

  message_list: function () {
    return axios.get(BASE_URL + '/messages', { headers: this.token_header() })
  },
  message_read: function (id) {
    return axios.get(BASE_URL + '/message/' + id, { headers: this.token_header() })
  },  
  message_delete: function (id) {
    return axios.delete(BASE_URL + '/message/' + id, { headers: this.token_header() })
  },

  type_list: function () {
    return axios.get(BASE_URL + '/types', { headers: this.token_header() })
  },
  type_create: function (data) {
    return axios.post(BASE_URL + '/type', data, { headers: this.token_header() })
  },
  type_read: function (id) {
    return axios.get(BASE_URL + '/type/' + id, { headers: this.token_header() })
  },
  type_update: function (id, payload) {
    return axios.put(BASE_URL + '/type/' + id, payload, { headers: this.token_header() })
  },
  type_delete: function (id) {
    return axios.delete(BASE_URL + '/type/' + id, { headers: this.token_header() })
  },


  image_upload_endpoint: function() {
    return BASE_URL + '/image';
  },

  login: function (email, password) {
    return axios.post(BASE_URL+ '/user/login', {email: email, password: password});
  },
  register: function (data) {
    return axios.post(BASE_URL+ '/user/register', data);
  },
  user_list: function(q) {
    return axios.get(BASE_URL + '/users' , {params: { q: q }, headers: this.token_header()})
  },
  user_create_admin: function(data) {
    return axios.post(BASE_URL + '/user/create_admin' , data, {headers: this.token_header()})

  },

  user_read: function(id) {
    return axios.get(BASE_URL + '/user/' + id, { headers: this.token_header() })
  },
  user_verify: function(user_id, verification_code) {
    var data = { verification_code: verification_code };
    return axios.post(BASE_URL + '/user/' + user_id + '/verify', data);
  },
  user_request_reset_password: function(data) {
    return axios.post(BASE_URL + '/user/request_reset_password' , data);
  },
  user_reset_password: function(user_id, forgot_password_code, password) {
    var data = { forgot_password_code: forgot_password_code, password: password };
    return axios.post(BASE_URL + '/user/' + user_id + '/reset_password', data);
  },
  user_update: function(id, data) {
    return axios.put(BASE_URL + '/user/' + id , data, {headers: this.token_header()})
  },
  user_change_activation: function(id, isdisabled) {
    return axios.post(BASE_URL + '/user/' + id + '/change_activation' , {isdisabled: isdisabled}, {headers: this.token_header()})
  },
  user_approve: function (id) {
    return axios.post(BASE_URL + '/user/' + id + '/approve', {}, { headers: this.token_header() })
  },
  user_disapprove: function (id) {
    return axios.post(BASE_URL + '/user/' + id + '/disapprove', {}, { headers: this.token_header() })
  },
  user_disable: function (id) {
    return axios.post(BASE_URL + '/user/' + id + '/disable', {}, { headers: this.token_header() })
  },
  user_enable: function (id) {
    return axios.post(BASE_URL + '/user/' + id + '/enable', {}, { headers: this.token_header() })
  },

  badge_list: function (page) {
    return axios.get(BASE_URL + '/badges', { headers: this.token_header(), params: { page } })
  },
  badge_create: function (data) {
    return axios.post(BASE_URL + '/badge', data, { headers: this.token_header() })
  },
  badge_read: function (id) {
    return axios.get(BASE_URL + '/badge/' + id, { headers: this.token_header() })
  },
  badge_update: function (id, payload) {
    return axios.put(BASE_URL + '/badge/' + id, payload, { headers: this.token_header() })
  },
  badge_delete: function (id) {
    return axios.delete(BASE_URL + '/badge/' + id, { headers: this.token_header() })
  },
  
  event_totals_group: function ({ event_id, filters = {}, page = 1 }) {
    return axios.get(
      BASE_URL + '/collectors/event_totals_group', 
      { 
        headers: this.token_header(),
        params: {
          event_id,
          filters,
          page
        }
      }
    )
  },
  event_collectors_totals_group: function ({ event_id, filters = {}, page = 1 }) {
    return axios.get(
      BASE_URL + '/collectors/event_collectors_totals_group', 
      { 
        headers: this.token_header(),
        params: {
          event_id,
          filters,
          page
        }
      }
    )
  },
  event_totals_type: function ({ event_id, filters = {}, page = 1 }) {
    return axios.get(
      BASE_URL + '/collectors/event_totals_type', 
      { 
        headers: this.token_header(),
        params: {
          event_id,
          filters,
          page
        }
      }
    )
  },
  event_collectors_totals_type: function ({ event_id, filters = {}, page = 1 }) {
    return axios.get(
      BASE_URL + '/collectors/event_collectors_totals_type', 
      { 
        headers: this.token_header(),
        params: {
          event_id,
          filters,
          page
        }
      }
    )
  },
  event_animals: function ({ event_id, filters = {}, page = 1 }) {
    return axios.get(
      BASE_URL + '/collectors/event_animals', 
      { 
        headers: this.token_header(),
        params: {
          event_id,
          filters,
          page
        }
      }
    )
  },
  event_items_of_local_concern: function ({ event_id, filters = {}, page = 1 }) {
    return axios.get(
      BASE_URL + '/collectors/event_items_of_local_concern', 
      { 
        headers: this.token_header(),
        params: {
          event_id,
          filters,
          page
        }
      }
    )
  },
  get_item_collector: function (item_collector_id) {
    return axios.get(
      BASE_URL + '/collector/' + item_collector_id, 
      { 
        headers: this.token_header(),
      }
    )
  },
  get_cleanup_types: function () {
    return axios.get(
      BASE_URL + '/collectors/cleanup_types', 
      { 
        headers: this.token_header(),
      }
    )
  },
}

export default API;
