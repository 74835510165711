import React, { useEffect } from 'react';
import { Table } from 'antd';
import useEventAnimals from '../hooks/useEventAnimals';
import useEventItemsOfLocalConcern from '../hooks/useEventItemsOfLocalConcern';
import {
    ListHeader,
    ListHeaderTitle
} from '../components';
import get from 'lodash/get';

const LocalConcernAndAnimals = ({
    eventId,
    cleanUpType
}) => {
    const [animalsFetching, animals = { data: [] }, updateAnimalsParams] = useEventAnimals({ 
        params: { 
            event_id: eventId,
            page: 1,
            filters: {
                type_of_cleanup: cleanUpType
            }
        }
    });
    const [localConcernFetching, itemsOfLocalConcern = { data: [] }, updateLocalConcernParams] = useEventItemsOfLocalConcern({ 
        params: { 
            event_id: eventId,
            page: 1,
            filters: {
                type_of_cleanup: cleanUpType
            }
        }
    });

    useEffect(() => {
        updateAnimalsParams({
            event_id: eventId, 
            filters: {
                type_of_cleanup: cleanUpType
            },
            page: 1,
        });
        updateLocalConcernParams({
            event_id: eventId, 
            filters: {
                type_of_cleanup: cleanUpType
            },
            page: 1
        });
    }, [cleanUpType, eventId]);
    
    const onConcernsTableChange = (pagination) => {
        updateLocalConcernParams({
          event_id: eventId, 
          filters: {
            type_of_cleanup: cleanUpType
          },
          page: pagination.current
        });
    };

    const onAnimalsTableChange = (pagination) => {
        updateAnimalsParams({
          event_id: eventId, 
          filters: {
            type_of_cleanup: cleanUpType
          },
          page: pagination.current
        });
    };

    return (
        <React.Fragment>
            <ListHeader>
                <ListHeaderTitle>Total Items Of Concern:</ListHeaderTitle>
            </ListHeader>
            <Table 
                dataSource={itemsOfLocalConcern.data} 
                loading={localConcernFetching}
                columns={[
                    {
                        title: 'Collected by ',
                        dataIndex: 'name'
                    },
                    {
                        title: 'Item Type',
                        dataIndex: 'collected_item_name'
                    },
                    {
                        title: '# of Collected',
                        dataIndex: 'count'
                    },
                ]} 
                rowKey='id' 
                onChange={onConcernsTableChange}
                pagination={{
                    total: get(itemsOfLocalConcern, 'total') || 0,
                    current: get(itemsOfLocalConcern, 'page') || 1,
                    pageSize: get(itemsOfLocalConcern, 'take') || 20
                }}
            />
            <ListHeader>
                <ListHeaderTitle>Total Animals:</ListHeaderTitle>
            </ListHeader>
            <Table 
                dataSource={animals.data} 
                loading={animalsFetching}
                columns={[
                {
                    title: 'Collected by ',
                    dataIndex: 'name'
                },
                {
                    title: 'Animal Type',
                    dataIndex: 'animal_type'
                },
                {
                    title: 'Dead',
                    dataIndex: 'dead',
                    render: (val) => val ? 'Yes' : 'No',
                },
                {
                    title: 'Entangled',
                    dataIndex: 'entangled',
                    render: (val) => val ? 'Yes' : 'No',
                },
                {
                    title: 'Type of Entanglement Item',
                    dataIndex: 'type_of_item'
                },
                ]} 
                rowKey='id' 
                onChange={onAnimalsTableChange}
                pagination={{
                    total: get(animals, 'total') || 0,
                    current: get(animals, 'page') || 1,
                    pageSize: get(animals, 'take') || 20
                }}
            />
        </React.Fragment>
    );
}

export default LocalConcernAndAnimals;