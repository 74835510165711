import { useState } from "react";
import API from 'services/APIService';
import { message } from "antd";

export default () => {
  // useful for loading icons
  const [isPosting, updateIsPosting] = useState(false);
  // set to false is default
  const [isSuccessful, updateIsSuccessful] = useState(false);
  /**
   * the actual data, default is undefined,
   * so a checker is needed when using the
   * returned data to evaluate it's existence
   */
  const [responseData, updateResponseData] = useState(undefined);

  const rePost = (userId) => {
    updateIsPosting(true);
    // always reset before the post request
    updateIsSuccessful(false);
    API.user_approve(userId)
      .then((response) => {
        updateIsSuccessful(true);
        updateResponseData(response.data);
        message.success('User passport membership has been approved.');
      })
      .catch((error) => {
        if (error.response.data) { 
          message.error(error.response.data.messages[0]); 
        } else { 
            message.error(error.message); 
        }
      })
      .finally(() => updateIsPosting(false));
  };

  /**
   * returning an array so that
   * end-user can use whatever
   * name for the values
   *
   * e.g.
   * const [loading, data] = useFetchData();
   */
  return [isPosting, rePost, isSuccessful, responseData];
};
