import React from "react";
import { Form, Button } from "antd";
import { Link } from "react-router-dom";

/**
 * @var getFieldDecorator simulates a form field so that
 * set this fields value when Partner.js mounts
 */
export default ({ formItemLayout, match, state, promos, logo, images }) => (
  <React.Fragment>
    <Form.Item {...formItemLayout} label="Promos">
      <Link
        to={{
          pathname: `/partner/${
            match.params.id ? match.params.id : "new"
          }/promo/${"new"}`,
          state: { ...state, promos, images, logo }
        }}
      >
        <Button type="primary">Add Perk</Button>
      </Link>
    </Form.Item>
  </React.Fragment>
);
