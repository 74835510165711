import React from 'react';
import CleanupTypeSelect from "./CleanupTypeSelect";
import DataDisplaySelect from './DataDisplaySelect';

const Parent = ({ children }) => {
    return (
        <div 
            style={{
                backgroundColor: '#FFFFFF',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            {children}
        </div>
    );
}

const Header = ({ children }) => {
    return (
        <div 
            style={{
                width: '100%',
                height: '60px',
                display: 'flex',
                flexDirection: 'column',
                alignSelf: 'stretch', 
                alignItems: 'center', 
                justifyContent: 'center'
            }}
        >
            {children}
        </div>
    );
}

const HeaderTitle = ({ children }) => {
    return (
        <div 
            style={{
                fontWeight: 'bold', 
                fontSize: 24, 
                color: '#348ED0',
                display: 'flex',
                flexDirection: 'row',
                alignSelf: 'stretch', 
                justifyContent: 'center',
            }}
        >
            {children}
        </div>
    );
}

const HeaderSubtitle = ({ children }) => {
    return (
        <div 
            style={{
                fontWeight: 'bold', 
                fontSize: 18, 
                color: '#348ED0',
            }}
        >
            {children}
        </div>
    );
}

const ListHeader = ({ children }) => {
    return (
        <div 
            style={{
                padding: 10, 
                // backgroundColor: '#44A4D0',
                marginBottom: '2px',
                background: 'linear-gradient(to right, #0B8DF2, #09BDF0)'
            }}
        >
            {children}
        </div>
    );
}

const ListHeaderTitle = ({ children }) => {
    return (
        <span 
            style={{
                color: '#FFFFFF', 
                fontWeight: 'bold', 
                fontSize: 22
            }}
        >
            {children}
        </span>
    );
}

const TotalParent = ({ children }) => {
    return (
        <div 
            style={{
                margin: '0 auto',
                display: 'grid',
                gap: '1rem',
                padding: '10px',
                // gridTemplateColumns: 'repeat(2, 1fr)',
                // '@media (minWidth: 600px)': {
                //     gridTemplateColumns: 'repeat(3, 1fr)'
                // },
                // '@media (minWidth: 900px)': {
                    gridTemplateColumns: 'repeat(5, 1fr)',
                // },
                backgroundColor: '#FFFFFFAA',
                padding: '10px'
            }}
        >
            {children}
        </div>
    );
}

const TotalCard = ({ children }) => {
    return (
        <div 
            style={{
                borderRadius: '10px',
                backgroundColor: '#EFEFEF',
                padding: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            {children}
        </div>
    );
}

const TotalTitle = ({ children }) => {
    return (
        <div 
            style={{
                color: '#44A4D0',
                fontWeight: 'bold',
                fontSize: '16px'
            }}
        >
            {children}
        </div>
    );
}

const TotalCount = ({ children }) => {
    return (
        <div 
            style={{
                color: '#348ED0',
                fontWeight: 'bold',
                fontSize: '32px'
            }}
        >
            {children}
        </div>
    );
}

export {
    CleanupTypeSelect,
    DataDisplaySelect,
    Parent,
    Header,
    HeaderTitle,
    HeaderSubtitle,
    ListHeader,
    ListHeaderTitle,
    TotalParent,
    TotalCard,
    TotalTitle,
    TotalCount
}