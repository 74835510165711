import React, { Component } from 'react';
import { Spin, Form, Input, Tooltip, Icon, Button, message, Tag } from 'antd';
import API from 'services/APIService';
import ImageGroup from '../ImageGroup';

const FormItem = Form.Item;

var DEFAULT_STATE = {
    submit_loading: false,
    item_loading: false,
    item: {},
    title: 'New Product',
    form_sizes: [],
    form_images: []
}

const formItemLayout = {
    labelCol: {
        lg: { span: 6 },
    },
    wrapperCol: {
        lg: { span: 18 },
    },
};

class Wrapper extends Component {
    constructor(props) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    componentDidMount() {
        console.log("componentDidMount");
        this.setState(DEFAULT_STATE);
        if (this.props.match.params.id !== 'new') {
            this._loadData(this.props.match.params.id);
        }
        else {
            this.setState(DEFAULT_STATE);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            console.log("componentDidUpdate");
            if (this.props.match.params.id !== 'new') {
                this._loadData(this.props.match.params.id);
            }
            else {
                this.setState(DEFAULT_STATE);
            }
        }
    }

    _loadData = (id) => {
        this.setState({item_loading: true});
        this.setState({ title: 'Edit Product' });
        API.product_read(id)
            .then((response) => {
                var data = response.data;
                this.setState({
                    item_loading: false,
                    item: data,
                    form_sizes: data.arr_sizes,
                    form_images: data.images,
                });
            })
            .catch((error) => {
                if (error.response.data) { message.error(error.response.data.messages[0]); }
                else { message.error(error.message); }
                this.setState({ item_loading: false });
                console.log(error);
            })
    }

    _handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ submit_loading: true });
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                var payload = values;
                // create custom payload
                payload.arr_sizes = this.sizesEditor.state.tags;
                payload.images = this.imagesEditor.state.images;
                // end create custom payload
                if (this.props.match.params.id === 'new') {
                    API.product_create(payload)
                        .then((response) => {
                            message.success('Product Created', 1);
                            this.setState({ submit_loading: false })
                            this.props.history.push('/products');
                            window.location.reload();
                        })
                        .catch((error) => {
                            if (error.response.data) { message.error(error.response.data.messages[0]); }
                            else { message.error(error.message); }
                            this.setState({ submit_loading: false });
                            console.log(error);
                        });
                }
                else {
                    API.product_update(this.props.match.params.id, payload)
                        .then((response) => {
                            message.success('Product Updated', 1);
                            this.setState({ submit_loading: false });
                            this.props.history.push('/products');
                        })
                        .catch((error) => {
                            if (error.response.data) { message.error(error.response.data.messages[0]); }
                            else { message.error(error.message); }
                            this.setState({ submit_loading: false });
                            console.log(error);
                        });
                }
            }
            else {
                message.error('Submit Error!', 4);
                this.setState({ submit_loading: false });
                console.log(err);
            }
        });

    }

    render() {
        if (this.state.item_loading) {
            return <div style={{ textAlign: 'center' }}><Spin size='large' style={{ margin: '150px' }} /></div>
        }
        const { getFieldDecorator } = this.props.form;
        var divForm = <Form onSubmit={this._handleSubmit} layout='vertical'>

            <FormItem {...formItemLayout} label="Name" >
                {getFieldDecorator('name', {
                    rules: [{
                        required: true, message: 'Please input!',
                    }],
                    initialValue: this.state.item.name
                })(
                    <Input />
                )}
            </FormItem>
            <FormItem {...formItemLayout} label="Description" >
                {getFieldDecorator('description', {
                    rules: [{
                        required: true, message: 'Please input!',
                    }],
                    initialValue: this.state.item.description
                })(
                    <Input />
                )}
            </FormItem>
            <FormItem {...formItemLayout} label="Price" >
                {getFieldDecorator('price', {
                    rules: [{
                        required: true, message: 'Please input!',
                    }],
                    initialValue: this.state.item.price
                })(
                    <Input />
                )}
            </FormItem>
            <FormItem {...formItemLayout} label="Sizes" >
                <EditableTagGroup tags={this.state.form_sizes} ref={(sizesEditor) => { this.sizesEditor = sizesEditor; }} />
            </FormItem>

            <FormItem {...formItemLayout} label="Images" >
                <ImageGroup limit={3} images={this.state.form_images} ref={(imagesEditor) => { this.imagesEditor = imagesEditor; }} />

            </FormItem>


            <FormItem style={{ textAlign: 'center' }}>
                <Button loading={this.state.loading} type="primary" htmlType="submit" className="login-form-button" style={{ width: '40%' }}>
                    Submit
                </Button>
            </FormItem>
        </Form>


        return (
            <div>
                <h1>{this.state.title}</h1>
                {divForm}
            </div>
        )
    }
}



class EditableTagGroup extends React.Component {
    state = {
        tags: [],
        inputVisible: false,
        inputValue: '',
    };

    componentDidMount() {
        console.log('componentDidMount()');
        this.setState({tags: this.props.tags});
    }

    handleClose = (removedTag) => {
        const tags = this.state.tags.filter(tag => tag !== removedTag);
        console.log(tags);
        this.setState({ tags });
    }

    showInput = () => {
        this.setState({ inputVisible: true }, () => this.input.focus());
    }

    handleInputChange = (e) => {
        this.setState({ inputValue: e.target.value });
    }

    handleInputConfirm = () => {
        const state = this.state;
        const inputValue = state.inputValue;
        let tags = state.tags;
        if (inputValue && tags.indexOf(inputValue) === -1) {
            tags = [...tags, inputValue];
        }
        console.log(tags);
        this.setState({
            tags,
            inputVisible: false,
            inputValue: '',
        });
    }

    saveInputRef = input => this.input = input

    render() {
        const { tags, inputVisible, inputValue } = this.state;
        return (
            <div>
                {tags.map((tag, index) => {
                    const isLongTag = tag.length > 40;
                    const tagElem = (
                        <Tag key={tag} closable={true} afterClose={() => this.handleClose(tag)}>
                            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                        </Tag>
                    );
                    return isLongTag ? <Tooltip title={tag} key={tag}>{tagElem}</Tooltip> : tagElem;
                })}
                {inputVisible && (
                    <Input
                        ref={this.saveInputRef}
                        type="text"
                        size="small"
                        style={{ width: 78 }}
                        value={inputValue}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputConfirm}
                        onPressEnter={this.handleInputConfirm}
                    />
                )}
                {!inputVisible && (
                    <Tag
                        onClick={this.showInput}
                        style={{ background: '#fff', borderStyle: 'dashed' }}
                    >
                        <Icon type="plus" /> New Size
          </Tag>
                )}
            </div>
        );
    }
}

const Product = Form.create()(Wrapper);
export default Product;
