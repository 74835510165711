import React from 'react';
import { Select } from 'antd';

const Option = Select.Option;

export default ({
  value,
  onChange,
}) => {
  const handleChange = (value) => {
    if (onChange) {
      onChange(value);
    }
  }

  return (
    <span>
      <Select
        showSearch
        value={value}
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={handleChange}
        style={{ width: '300px' }}
      >
        <Option key={'group'} value={'group'}>By Group</Option>
        <Option key={'type'} value={'type'}>By Type</Option>
      </Select>
    </span>
  );
}